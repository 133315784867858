.app {
    display: flex;

    &.app--dropdown-choose-part {
        .app__bottom-bar {
            z-index: 0;
        }
    }

    &.app--dropdown {
        .nav__opener{
            z-index: 3;
        }

        .app__content {
            overflow: hidden;
            max-height: 100vh;
        }
    }

    &.app--dropdown-bauteil-wahlen {
        .app__bottom-bar {
            z-index: 2;
        }
    }

    .app__spotlight--sidebar {
        height: 100%;
        width:100%;
        top:0;
        left:0;
        position: fixed;
        background-color: black;
        opacity: .7;
        z-index: 12;

        @media (min-width:map-get($grid-breakpoints, md)){
            display:none;
        }
    }

    .app__status {
        text-align: right;
        margin-bottom: 0 auto;
        position: fixed;
        top:0;
        right:0;
        width: 100%;
        z-index: 11;
        padding: 11px 15px 11px 60px;
        @media (min-width:map-get($grid-breakpoints, md)){
            width: calc(100% - 100px);
            padding: 11px 0;
        }

        // @media (min-width:map-get($grid-breakpoints, lg)){
        //     padding: 15px;
        // }

        // @media (min-width:map-get($grid-breakpoints, xl)){
        //     padding: 22px 15px 0 15px;
        // }
    }

    .app__sidebar {

        position: fixed;
        top:0;
        left:-80vw;
        z-index: 6;
        width: 80vw;
        height: 100vh;
        transition: left .3s;
        background-color: map-get($colors, seamless);

        @media (min-width:map-get($grid-breakpoints, md)){
            width: map-get($app, sidebarWidth-md);
            z-index: 1;
            left:0;
            border-right: 1px solid map-get($colors, grey100);
        }
        
        // @media (min-width:map-get($grid-breakpoints, sm)) {
        //     width: map-get($app, sidebarWidth-sm);
        // }

        .btn--icon-close{
            position: absolute;
            right: 15px;
            top: -4px;

            @media (min-width:map-get($grid-breakpoints, md)){
                display: none;
            }
        }

        .app__sidebar-inner {
            height: calc(100vh);
            position: relative;

            @media (min-width:map-get($grid-breakpoints, md)){
                height: calc(100vh);
            }

            /** modified **/
            &>div {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
            }
            /** modified end **/

            .nav {
                display: flex;
                flex-direction: column;
                // height: 100%;

                li {
                    &:last-child {
                        margin-top: auto;
                    }
                }
            }
        }

        // open nav on mobile
        //--------------------------------------------------------

        &.app__sidebar--show {
            left: 0;
            z-index: 13;
        } 
    }

    .app__content {
        padding: 115px 15px 30px 15px;
        background-color: map-get($colors, seamless);
        min-height: calc(100vh);
        max-width: 100vw;
        width:100%;
        position: relative;
        transition: opacity map-get($times, fast), transform map-get($times, medium);
        opacity: 1;
        margin-left: auto;

        @media (min-width:map-get($grid-breakpoints, md)){
            width: calc(100% - #{map-get($app, sidebarWidth-sm)});
            padding: 115px 0 60px 0;
        }

        @media (min-width:map-get($grid-breakpoints, lg)){
            padding-top: 170px;
        }

        &.app__content--attribute {
            @media (min-width:map-get($grid-breakpoints, md)){
                padding-top: 136px;
            }
            @media (min-width:map-get($grid-breakpoints, lg)){
                padding-top: 150px;
            }
        }

        &.app__content--default {
            padding-top: 125px;

            @media (min-width:map-get($grid-breakpoints, lg)){
                padding-top: 140px;
            }
        }

        &.app__content--statistics {
            padding-top: 160px;
            @media (min-width:map-get($grid-breakpoints, md)){
                padding-top: 181px;
            }
            @media (min-width:map-get($grid-breakpoints, lg)){
                padding-top: 134px;
            }
        }

        //*******************************************************
        // loading
        //*******************************************************

        &:after {
            content: '';
            display:block;
            height: 100%;
            width:100%;
            top:0;
            left:0;
            position: absolute;
            opacity: 0;
            z-index: -10;
            transition: opacity .3s;
            background-image: url(../../img/loader.svg);
            background-position: center center;
            background-repeat: no-repeat;
        }
    }

    &.app--isLoading {
        .app__content {
            position: relative;
            &:after {
                opacity: .9;
                z-index: 2;
            }
        }
    }

    .app__main {
        position: relative;

        &:after {
            content: '';
            display:block;
            height: 100%;
            width:100%;
            top:0;
            left:0;
            position: fixed;
            opacity: 0;
            z-index: -10;
            transition: opacity .3s;
            background-image: url(../../img/loader.svg);
            background-position: center center;
            background-repeat: no-repeat;
        }

        //for stats
        &.isLoading {
            &:after {
                opacity: .9;
                z-index: 0;
            }
        }
    }

    .app__main-sticky-footer,
    .app__main-header {
        width: 100%;
        padding: 10px 15px;
        top:45px;
        @media (min-width:map-get($grid-breakpoints, md)){
            width: calc(100% - 100px);
            padding: 20px 0;
            top:48px;
        }
        position: fixed;
        right: 0;
        z-index: 3;
        background-color: map-get($colors, seamless);

        &.app__main-header--static {
            position: absolute;
            top: -90px;
            left:-15px;
        }
    }

    .app__main-sticky-footer {
        top: inherit;
        bottom: 0;
    }

    .app__main-content--mobile-pocket {
        padding-bottom: 50px;

        @media (min-width:map-get($grid-breakpoints, md)){
            padding-bottom: 0;
        }
    }

    .app__overlay {
        position: fixed;
        top: 0;
        right: -100vw;
        background-color: map-get($colors-dark, action);
        width: 100%;
        transition: map-get($times, fast);
        height: 100%;
        min-height: 650px;
        z-index: 10;
        overflow: hidden;
        overflow-y: scroll;
        padding-top: 62px;

        @media (min-width:map-get($grid-breakpoints, md)) {
            width: calc(100vw - #{map-get($app, sidebarWidth-md)});
            padding-top: 76px;
        }

        &.app__overlay--show {
            position: absolute;
            right: 0;
            overflow-y: scroll;
            // overflow: hidden;
            // @media (hover: none) {
            //     overflow-y:scroll;
            // }
            &.fixed {
                position: fixed;
            }
        }

        .app__overlay-form {
            height: 100%;
            display: flex;
            flex-direction: column;
            padding-left: 15px;
            padding-right: 15px;

            //*******************************************************
            // android soft keyboard fixes
            //*******************************************************

            // @media (max-height: 801px){
            //     min-height: 1100px;
            // }

            // @media (max-height: 700px) and (min-width:map-get($grid-breakpoints, md)) and (max-width:map-get($grid-breakpoints, lg)){
            //     min-height: 1000px;
            // }

            // @media (max-height: 600px) and (min-width:map-get($grid-breakpoints, lg)) and (max-width:map-get($grid-breakpoints, xl)){
            //     min-height: 1200px;
            // }
        }
    }

    .app__bottom-bar {
        position: fixed;
        bottom: 0;
        left:0;
        @media (min-width:map-get($grid-breakpoints, md)){
            left: 15px;
        }
        width: 100%;
        background-color: map-get($colors-dark, dark);
        z-index: 3;
        align-items: center;
        justify-content: center;

        &.active {
            z-index: 12;
            background-color: transparent !important;
        }

        .app__bottom-bar__inner {
            border-bottom: 5px solid map-get($colors-dark, seamless);
            display: flex;
            padding: 10px 0 0 15px;
            @media (min-width:map-get($grid-breakpoints, md)){
                padding: 10px 0 0 0;
            }
        }

        .export-tab {

            overflow: hidden;
            transition: all .3s;
            max-height: 0;
            min-height: 0;

            &.active {
                min-height: 80vh;
                max-height: 80vh;
                overflow: scroll;
            }

            .export-tab__inner {
                background-color: map-get($colors-dark, seamless);
                max-height: 80vh;
                min-height: 80vh;
                overflow: scroll;
                padding: 15px;

                @media (min-width:map-get($grid-breakpoints, md)){
                    padding: 30px;
                }
            }

            .export-tab__close {
                display:block;
                height: 100vh;
                width:100vw;
                top:0;
                left:0;
                position: fixed;
                background-color: rgba($color: #000000, $alpha: .5);
                z-index: -1;
            }
        }

        .app__bottom-bar-opener-wrapper {
            padding: 5px 5px 2px 5px;
            @media (min-width:map-get($grid-breakpoints, lg)){
                padding: 10px 10px 5px 10px;
            }
            display: flex;
            border-top-left-radius:$border-radius-stnd;
            border-top-right-radius: $border-radius-stnd;
            position: relative;

            &.active {
                .app__bottom-bar-opener {
                    &:after {
                        top: 45%;
                        transform: rotate(135deg) translate(0%, 70%);
                    }
                }
            }

            .app__bottom-bar-opener {

                display: block;
                height: 40px;
                width: 40px;
                @media (min-width:map-get($grid-breakpoints, lg)){
                    height: 45px;
                    width: 45px;
                }
                @media (min-width:map-get($grid-breakpoints, xl)){
                    width: 55px;
                    height: 55px;
                }
                position: relative;
                border-radius: 50%;
                background-color: map-get($colors-dark, action);

                &:after {
                    content: '';
                    display:block;
                    height: 10px;
                    width:10px;
                    top:50%;
                    left:50%;
                    position: absolute;
                    border-right: 3px solid white;
                    border-top: 3px solid white;
                    transform: rotate(-45deg) translateY(-70%);
                    transition: all .3s;
                    transform-origin: center;
                 }
            }
        }

        @media (min-width:map-get($grid-breakpoints, md)){
            width: calc(100vw - #{map-get($app, sidebarWidth-md)} - 30px );
            right:15px;
            left:inherit;
        }

        .app__bottom-bar-machine-dropdown {
            max-width: 40%;
        }
    }
}

//*******************************************************
// dark mode
//*******************************************************

    .dark-mode {

        color: white;
        background-color: map-get($colors-dark, dark);

        .app__status {
            background-color: map-get($colors-dark, grey-feather);
        }

        .app__sidebar {
            background-color: map-get($colors-dark, dark);
            border-right: 1px solid map-get($colors-dark, border);
        }

        .app__content {
            background-color: map-get($colors-dark, dark);
            //loader
            &:after {
                background-color: map-get($colors-dark, dark);
            }
        }

        .app__bottom-bar {

            .app__bottom-bar-opener-wrapper {
                background-color: map-get($colors-dark, seamless);
            }

            .app__bottom-bar-opener {
                border: 1px solid white
            }
        }

        .app__main {
            &:after {
                background-color: map-get($colors-dark, dark);
            }
        }

        .app__main-sticky-footer,
        .app__main-header {
            background-color: map-get($colors-dark, dark);
        }
    }


//*******************************************************
// light mode
//*******************************************************

    .light-mode {

        background-color: map-get($colors, seamless);

        .app {

            .app__status {
                background-color: map-get($colors, grey100);
            }

            .app__content{
                //loader
                &:after {
                    background-image: url(../../img/loader-dark.svg);
                    background-color: map-get($colors, seamless);
                }
            }

            .app__bottom-bar {
                background-color: map-get($colors, seamless);
                .app__bottom-bar__inner {
                    border-bottom: 5px solid map-get($colors, grey100);
                }
                .app__bottom-bar-opener-wrapper {
                    background-color: map-get($colors, grey100);
                }
                .app__bottom-bar-opener {
                    border: 1px solid map-get($colors, primary)
                }
            }

            .export-tab {

                .export-tab__inner {
                    background-color: map-get($colors, grey100);

                    .card {
                        background-color: map-get($colors, grey);
                    }
                }
            }

            .app__main {
                &:after {
                    background-image: url(../../img/loader-dark.svg);
                    background-color: map-get($colors, seamless);
                }
            }
        }
    }


//*******************************************************
// if dropdown opened
//*******************************************************

    .app--dropdown {
        .app__status {
            z-index: 2;
        }

        .app__main-sticky-footer {
            z-index: 2;
        }
    }


//*******************************************************
// if connect screen
//*******************************************************

    .app--connect {
        .app__status,
        .app__content {
            width: 100%;
        }

        .app__status {
            padding: 11px 15px;
        }
        
        .app__content {
            padding-top: 0;
            padding-bottom: 0;
            min-height: calc(100vh - 6rem);
        }
    }