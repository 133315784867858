//*******************************************************
// Structure
//*******************************************************

    $app: (
        sidebarWidth-xs: 70px,
        sidebarWidth-sm: 100px,
        sidebarWidth-md: 100px,
    );

    $container-delta: 140px;
    $container-delta-xs: 125px;
    $container-delta-minor: $container-delta - 55px;
    $container-delta-minor-xs: $container-delta-xs - 55px;


//*******************************************************
// colors
//*******************************************************

    $colors:(
        primary: rgba(0, 22, 98, 1),
        grey100: #E4E4E9,
        third: #aaa,
        white: #ffffff,
        black: #000,
        danger: #ed1c24,
        success: #37a356,
        info: orange,
        action: #4A5ABF,
        // white05: rgba(#ffffff, .4),
        // white05: rgba(132, 140, 147, 1),
        seamless: rgba(249, 249, 249, 1),
        grey: rgba(190, 194, 200, 1),
        greyTransp: rgba(191, 195, 198, 1),
        grey-dark: #989DA2,
    );

    $colors-dark:(
        dark: #191B1D,
        darker: rgb(27, 34, 41),
        third: rgba(345, 345, 345, .39),
        danger: #F1334F,
        danger-dark: #bc283d,
        danger-negative: #ea5b70,
        success: #37a356,
        success-dark: #2c8446,
        action: #4A5ABF,
        action-active: #4250A6,
        action-dark: rgba(45, 63, 137, 1),
        grey-dark: #141B23,
        grey-medium: #263341,
        grey-light: #2F3B49,
        grey-medium-transp: rgba(#2F3B49, .5),
        grey-medium-notransp: rgba(80, 80, 80, 1),
        grey-extralight: #46515D,
        grey-feather: rgba(47, 64, 77, 1),
        seamless: #2a3440,
        border: rgba(#979797, 0.4),
    );


//*******************************************************
// fontsizes (use false if it should be empty !!!!)
//*******************************************************

    $fonts:(

        xs:(
            1:(
                font-size: 25px,
                font-weight: bold,
            ),
            2:(
                font-size: 20px,
                font-weight: bold,
            ),
            3:(
                font-size: 14px,
                font-weight: bold,
            ),
            p:(
                font-size: 13px,
            ),
            small:(
                font-size: 11px,
            ),
            mini:(
                font-size: 10px,
            ),
        ),

        sm: false,
        md: (
            1:(
                font-size: 30px,
            ),
            2:(
                font-size: 25px,
            ),
            3:(
                font-size: 15px,
            ),
            p:(
                font-size: 14px,
            ),
            small:(
                font-size: 13px,
            ),
        ),
        lg: (
            1:(
                font-size: 30px,
            ),
            2:(
                font-size: 22px,
            ),
            3:(
                font-size: 17px,
            ),
            4:(
                font-size: 18px,
            ),
            p:(
                font-size: 15px,
            ),
            small:(
                font-size: 12px,
            ),
        ),
        xl: false,
        xxl:(
            1:(
                font-size: 40px,
            ),
            2:(
                font-size: 30px,
            ),
            3:(
                font-size: 22px,
            ),
            4:(
                font-size: 18px,
            ),
            p:(
                font-size: 16px,
            ),
            small:(
                font-size: 13px,
            ),
        ),
        max: false,
    );


//*******************************************************
// breaking points
//*******************************************************

    $grid-breakpoints: (
        xs: 0,
        sm: 576px, 
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1600px,
        max: 2560px,
    );


//*******************************************************
// spacing
//*******************************************************

    $spacing: (
        xs: (
            0:50px,
            1:40px,
            2:25px,
            3:15px,
            4:10px,
            35: 35px,
            grid: 30px,
            reset:0,
        ),
        md: (
            0:100px,
            1:50px,
            2:50px,
            3:30px,
            4:20px,
            35: 35px,
            reset:0,
        ),
        lg: (
            0:100px,
            1:60px,
            2:50px,
            3:30px,
            4:20px,
            35: 35px,
            reset:0,
        ),
        xl: (
            0:100px,
            1:70px,
            2:50px,
            3:30px,
            4:20px,
            35: 35px,
            reset:0,
        ),
    );


//*******************************************************
// Times
//*******************************************************

    $times: (
        fast: .5s,
        medium: 1s,
        slow: 1.5s,
    );


//*******************************************************
// buttons
//*******************************************************

    $buttons: (
        xs:(
            line-height: 40px,
            height: 40px,
            font-size: 11px,
            letter-spacing: 0px,
            padding: 0 15px,        
        ),
        lg:(
            line-height: 45px,
            height: 45px,
            font-size: 13px,
            letter-spacing: 0px,
            padding: 0 15px,        
        ),
        xl:(
            line-height: 54px,
            height: 54px,
            font-size: 14px,
            padding: 0 20px,        
        )
    );

    $btnPinHeight: 2em;


//*******************************************************
// borders
//*******************************************************

    $border-radius-stnd: 2px;
    $border-radius-big: 2px;



//*******************************************************
// radios
//*******************************************************

    $radioWidth: 60px;
    $handleSize: 28px;
    $radioHeight: 30px;


//*******************************************************
// table
//*******************************************************

    $cellSpace : 1em;
    $circleImageWidth : 20px;