.react-confirm-alert-overlay {
    background-color: rgba(black, .7);
    
    .react-confirm-alert {
        background-color: map-get($colors-dark, action);
        color: white;
        display:flex;
        align-items: center;
        flex-direction: column;
        min-height: 20vh;
        justify-content: center;
        width: 90%;
        max-width: 90%;
        border-radius: 5px;
        padding: 30px;
        text-align: center;
        
        @media (min-width:map-get($grid-breakpoints, md)){
            width: auto;
            min-width: 50vw;
        }

        @media (min-width:map-get($grid-breakpoints, lg)){
            width: 40vw
        }

        @media (min-width:map-get($grid-breakpoints, xxl)){
            min-width: 30vw
        }

        .react-confirm-content {
            width: 100%;
            position: relative;
        }

        .react-confirm-scroll {
            max-height: 60vh;
            overflow-y: scroll;
        }
    }
}


.modal {
    position: absolute;
    top:0;
    left:0;
    min-height: 100vh;
    z-index: 11;
    width: 100%;
    display:flex;
    align-items: center;
    justify-content: center;

    * {
        position: relative;
        z-index: 2;
    }

    &:after {
       content: '';
       display:block;
       height: 100%;
       width:100%;
       top:0;
       left:0;
       position: absolute;
       background-color: black;
       opacity: .7;
       z-index: 1;
    }

    .modal__content {
        
        background-color: map-get($colors-dark, action);
        min-height: 20vh;
        width: 90%;
        @media (min-width:map-get($grid-breakpoints, md)){
            width: 50vw
        }

        @media (min-width:map-get($grid-breakpoints, lg)){
            width: 40vw
        }

        @media (min-width:map-get($grid-breakpoints, xl)){
            width: 30vw
        }
        padding: 30px;
        position: relative;
        border-radius: 4px;
        text-align: center;
        display:flex;
        align-items: center;
        flex-direction: column;

        * {
            width: 100%;
        }

        .btn--icon-close {
            position: absolute;
            top:-69px;
            right:-66px;
        }
    }
}