

.naming-row-container {
    width: 50%;

    @media (max-width:map-get($grid-breakpoints, xl)){
        width: 60%;
    }

    @media (max-width:map-get($grid-breakpoints, lg)){
        width: 70%;
    }

    @media (max-width:map-get($grid-breakpoints, md)){
        width: 80%;
    }
}

.naming-row-input-container {
    display: flex;
    flex-direction: row;
    margin-top: 2px;

    @media (max-width:map-get($grid-breakpoints, md)){
        flex-direction: column;
    }
}

.naming-input {
    width: 48%;
    @media (max-width:map-get($grid-breakpoints, md)){
        width: 100%;
    }
    &.top {
        margin-right: 20px;
        @media (max-width:map-get($grid-breakpoints, md)){
            margin-right: 0px;
            margin-bottom: 15px;
        }
    }
}

.info-block {
    display: flex;
    flex-direction: row;
    background-color: map-get($colors-dark, action-dark);
    margin-top: 5px;
    padding: 5px;
    justify-content: space-between;

    @media (max-width:map-get($grid-breakpoints, md)){
        flex-direction: column;
    }

    &.info-block__img {
        width: 45px;
        height: 45px;
        padding-top: 7px;
        object-fit: cover;
    }

    &.info-block__div {
        width: 95%;
        line-height: 1.5em;
        padding-top: 10px;
        padding-bottom: 13px;
        padding-right: 10px;
        object-fit: cover;
        text-transform: none;
        display: flex;
        flex-direction: column;
        p {
            padding: 0;
            margin: 0;
            // margin-bottom: 3px;
        }

        @media (max-width:map-get($grid-breakpoints, md)){
            padding-top: 0px;
            padding-left: 10px;
            padding-right: 0px;
        }
    }
}