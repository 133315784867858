.table__wrapper {

        min-height: 0;
        display: flex;
    }

    table {

        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        position: relative;
        z-index: 0;

        &.table--promess {

            thead {

                th {
                    width: 11%;
                    svg {
                        min-width: 12px;
                    }
                    &.active {
                        &.asc {
                            svg {
                                transform: scaleX(-1);
                            }
                        }
                    }

                    .limit {
                        max-width: 60px;
                        @media (min-width:map-get($grid-breakpoints, xl)){
                            max-width: 100%;    
                        }
                        
                    }
                }

                tr {
                    background-color: transparent
                }
            }

            td,
            th {
                text-align: left;
                padding: $cellSpace;
                border:none;
            }   
            
            tr {
                padding:0 0 10px 0;
                background-color: map-get($colors, grey100);

                &.tr-space {
                    height: 10px;
                }
            }

            td {
                border-top: 1px solid rgba(white, .9) !important;
                border-bottom: 1px solid rgba(white, .9) !important;
                font-weight: 700;
                position: relative;

                @media (min-width:map-get($grid-breakpoints, lg)){
                    &:first-child {
                        border-left: 1px solid rgba(white, .9) !important;
                        border-top-left-radius: $border-radius-stnd ;
                        border-bottom-left-radius: $border-radius-stnd ;
                    }
                    &:last-child {
                        border-right: 1px solid rgba(white, .9) !important;
                        border-top-right-radius: $border-radius-stnd ;
                        border-bottom-right-radius: $border-radius-stnd ;
                    }
                }

                &.chart-container {
                    position: relative;

                    .btn--pin-trash {
                        position: absolute;
                        top:-5px;
                        right:-10px;
                    }
                }

                .cell-limiter {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    display: block;
                    margin:0;

                    @media (min-width:map-get($grid-breakpoints, lg)){
                        position: absolute;
                        top:50%;
                        left:$cellSpace;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        max-width: calc(100% - 2 * #{$cellSpace});
                        margin-top: calc(-#{$cellSpace}/2);

                        &.cell-limiter--with-image {
                            left: calc(#{$circleImageWidth} + #{$cellSpace} + 5px);
                            max-width: calc(100% - #{$circleImageWidth} - #{$cellSpace} - 5px);
                        }
                    }
                }
            }

            &.table--promess-invert {
                tbody {
                    tr {
                        background-color: map-get($colors-dark, darker);
                    }
                }
            }

            @media (max-width:map-get($grid-breakpoints, lg)){
                thead {
                    display: none;
                }

                tr {
                    display: flex;
                    flex-wrap: wrap;
                    position: relative;

                    .btn--pin-trash {
                        position: absolute;
                        top:-5px;
                        right:-8px;
                    }
                }

                tr.tr-space {
                    height: 15px;
                    display: block;
                    background-color: transparent !important;
                }
                
                td {
                    padding: .7em;
                    border:none !important;
                    width: 33.33%;

                    &.td-part {
                        order: -1;
                    }

                    &.td-time {
                        width: 66.66%;
                    }

                    span {
                        margin-left: 3px;
                    }

                    &.chart-container {
                        position: relative;
                        height: 30px;
                        width: 100%;
                        padding: 0;
                    }

                    br {
                        display: none;
                    }
                }
                
                td::before {
                content: attr(data-label);
                font-weight: bold;
                width: 100%;
                opacity: .7;
                display: block;
                text-transform: uppercase;
                margin-bottom: 5px;
                }
            }
        }


        &.table--white {
            tr {
                background-color: transparent;
            }

            td,
            th {
                border:none !important;
                border-top: 1px solid rgba(white, .2) !important;
                border-radius: 0;
                text-align: left;
                padding: 1em;
                // border:none;
            } 
        }
    }


//*******************************************************
// light mode
//*******************************************************

    .light-mode {
        table {
            &.table--promess {
                tr {

                }
                .text-grey {
                    color: map-get($colors, grey-dark) !important;
                }

                thead {
                    th {
                        &.active {
                            color: black;
                        }
                    }
                }
            }
        }
    }


//*******************************************************
// DARK MODE
//*******************************************************

    .dark-mode {
        table {

            &.table--promess {
                

                thead {

                    th {
                        font-weight: 300;

                        &.active {
                            color: white;
                            font-weight: 700;
                        }
                    }
                }

                tr {
                    // color: map-get($colors, grey-dark);
                }

                tbody {
                    tr {
                        background-color: map-get($colors-dark, grey-feather);
                    }
                }

                td {
                    @media (min-width:map-get($grid-breakpoints, lg)){
                        border-top: 1px solid rgba(white, .2) !important;
                        border-bottom: 1px solid rgba(white, .2) !important;

                        &:first-child {
                            border-left: 1px solid rgba(white, .2) !important;
                        }
                        &:last-child {
                            border-right: 1px solid rgba(white, .2) !important;
                        }
                    }
                }
            }

            .hidden-table {
                td {
                    background-color: map-get($colors-dark, seamless); 
                    border-top:none;
                }
            }
        }
    }


.td-reset {
    padding:0;
    border:none !important;
}