//*******************************************************
// SIZES
//*******************************************************

    @each $query_name, $query_value in $grid-breakpoints {    
        //if breaking point not empty (= false)
        @if map-get($buttons, $query_name) != false and map-get($buttons, $query_name) != null  {   
            input[type="text"].DateInput_input,
            .button,         
            .btn:not(.btn--badge) {
                @media (min-width: $query_value){

                    @if map-deep-get($buttons, $query_name, font-size) != null {            
                        font-size: map-deep-get($buttons, $query_name, font-size);
                    }

                    @if map-deep-get($buttons, $query_name, height) != null {            
                        height: map-deep-get($buttons, $query_name, height);
                    }

                    @if map-deep-get($buttons, $query_name, line-height) != null {            
                        line-height: map-deep-get($buttons, $query_name, line-height);
                    }

                    @if map-deep-get($buttons, $query_name, padding) != null {            
                        padding: map-deep-get($buttons, $query_name, padding);
                    }
                    
                    @if map-deep-get($buttons, $query_name, letter-spacing) != 0px and map-deep-get($buttons, $query_name, letter-spacing) != null {            
                        letter-spacing: map-deep-get($buttons, $query_name, letter-spacing);
                    }
                }
            }
        }  
    }

//*******************************************************
// Global
//*******************************************************

    // input[type="text"].DateInput_input,
    .btn {
        background-color: map-get($colors-dark, action);        
        -webkit-appearance: none;
        outline:none;
        transition: all map-get($times, fast);
        color: white;
        display:block;
        font-family: 'Roboto-Bold', Arial, sans-serif;
        text-transform: uppercase;
        border-radius: $border-radius-stnd;
        background-repeat: no-repeat;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        font-weight: normal;
        text-decoration: none;

        .btn__count {
            display: inline-block;
            background-color: map-get($colors, primary);
            width: 1.5em;
            line-height: 1.5em;
            height: 1.5em;
            margin-right: .5em;
            border-radius: 50%;
        }

        &.btn--reset {
            background-color: transparent;
            color: map-get($colors, white05);
            border: none !important;
            cursor: default;
        }

        &.btn--card {
            border-radius: $border-radius-big;
        }

        &.btn--clear {
            background-color: transparent;
            width: auto;
            height: auto;
            line-height: auto;
            color: inherit;
            -webkit-appearance: none;
            text-transform: none;
            font-size: inherit;
        }

        &.btn--white {
            background-color: white;
            color: map-get($colors, primary);
        }

        &.btn--danger {
            background-color: map-get($colors-dark, danger);
        }

        &.btn--white-negative {
            background-color: transparent;
            color: white;
           border: 1px solid rgba(345, 345, 345, .3);
        }

        &.btn--loading {
            background-image: url(./../../img/loader.svg) !important;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 70% 70%;
            color: transparent !important;
        }

        &:disabled {
            cursor: not-allowed;

            i {
                opacity: .3;
            }
        }

        &:not(.btn--info):not(.btn--reset):not(.btn--disable-hover){

            &:hover,
            &:active {
                opacity: .7;
                cursor: pointer;
            }
        }

        &.btn--icon {
            width: 40px;
            @media (min-width:map-get($grid-breakpoints, lg)){
                width: 45px;
            }
            @media (min-width:map-get($grid-breakpoints, xl)){
                width: 54px;
            }
            border-radius: 50%;
            background-position: center center;
            background-size: 70%;
            background-repeat: no-repeat;
            padding:0;
            display:inline-block;
            // background-color: map-get($colors, grey);

            &.btn--icon-plus {
                background-image: url(./../../img/icons/icon-plus-white.svg);

                &.is-active {
                    transform: rotate(-45deg);
                }
            }

            &.btn--icon-check {
                background-image: url(./../../img/icons/icon-checkmark-white.svg);
                background-size: 40%;

                &.btn--icon-white {
                    background-image: url(./../../img/icons/icon-checkmark.svg);
                    background-color: white;
                }
            }

            &.btn--icon-md {
                height: 4em;
                width: 4em;
            }

            &.btn--icon-lg {
                height: 7em;
                width: 7em;
            }

            &.btn--icon-xl {
                padding: 1.5em;
                height: 10em;
                width: 10em;
            }

            &.btn--icon-prev,
            &.btn--icon-next {
                
                position: relative;

                &:after {
                    content: '';
                    display:block;
                    height: 30%;
                    width:30%;
                    border-top: 1px solid white;
                    border-right: 1px solid white;
                    top:50%;
                    left:50%;
                    position: absolute;
                    transform: translate(-75%, -50%) rotate(45deg);
                }
            }

            &.btn--icon-prev {
                &:after {
                    transform: translate(-25%, -50%) rotate(-135deg);
                }
            }

            &.btn--icon-last {
                position: relative;
                &:before,
                &:after {
                    content: '';
                    display:block;
                    height: 30%;
                    width:30%;
                    border-top: 1px solid white;
                    border-right: 1px solid white;
                    top:50%;
                    left:45%;
                    position: absolute;
                    transform: translate(-85%, -50%) rotate(45deg);
                }

                &:before {
                    left: 55%;
                    transform: translate(-65%, -50%) rotate(45deg);
                }
            }

            &.btn--icon-first {
                position: relative;
                &:before,
                &:after {
                    content: '';
                    display:block;
                    height: 30%;
                    width:30%;
                    border-top: 1px solid white;
                    border-right: 1px solid white;
                    top:50%;
                    left:45%;
                    position: absolute;
                    transform: translate(-35%, -50%) rotate(-135deg);
                }

                &:before {
                    left: 55%;
                    transform: translate(-15%, -50%) rotate(-135deg);
                }
            }

            &.btn--naked {
                border: none !important;
                background-color: transparent !important;
            }

            &.btn--action {
                background-color:map-get($colors-dark, action) !important;
            }

            &.btn--icon-close {
                background-image: url(./../../img/icons/icon-plus-white.svg);
                background-size: 90%;
                transform: rotate(-45deg);
            }

            &.btn--icon-delete {
                background-image: url(./../../img/icons/icon-plus-white.svg);
                background-color: darkred;
                background-size: 90%;
                transform: rotate(-45deg);
            }

            &.btn--icon-edit {
                background-image: url(./../../img/icons/icon-edit-white.svg);
                background-size: 40%;

                &.is-active {
                    background-image: url(./../../img/icons/icon-checkmark-white.svg);
                }
            }

            &.btn--cloud {
                background-image: url(./../../img/icons/icon-cloud.svg);
                background-size: 60%;
            }
        }

        &.btn--icon-taster2 {
            background-image: url(./../../img/icons/icon-taster-2-white.svg);
            background-position: center center;
            background-color: map-get($colors-dark, action-dark);
            background-size: 25px;
            @media (min-width:map-get($grid-breakpoints, md)){
                background-size: 40px;
            }

            &.btn--active {
                background-color: white !important;
                background-image: url(./../../img/icons/icon-taster-2.svg);
            }
        }

        &.btn--icon-taster3 {
            background-image: url(./../../img/icons/icon-taster-3-white.svg);
            background-position: center center;
            background-size: 25px;
            @media (min-width:map-get($grid-breakpoints, md)){
                background-size: 30px;
            }
            @media (min-width:map-get($grid-breakpoints, xl)){
                background-size: 40px;
            }
            background-color: map-get($colors-dark, action-dark);

            &.btn--active {
                background-color: white !important;
                background-image: url(./../../img/icons/icon-taster-3.svg);
            }
        }

        &.btn--fat {
            height: 50px;
            @media (min-width:map-get($grid-breakpoints, xl)){
                height: 74px;
            }
        }

        &.btn--info {
            border-radius: 2em;
            cursor: default;
            border:none !important;
        }

        &.btn--double {
            background-position: 5% center !important;
            background-size: 25% !important;
            position: relative;
            display:flex;
            align-items: center;
            padding-left: 0;

            i {
                padding: 0 20px;
                height: inherit;
                border-radius: 0;
                margin-right: 10px;

                @media (min-width:map-get($grid-breakpoints, xl)){
                    margin-right: 20px;
                    padding: 0 30px;
                }
            }
        }

        &.btn--dropdown {
            border-radius: 2em;
            position: relative;
            text-align: center;
            padding-right: 2.5em;

            &.btn--dropdown-corners { 
                border-radius: $border-radius-stnd;
            }

            &:after {
                content: '';
                display:block;
                height: 0;
                width:0;
                top:50%;
                position: absolute;
                right: 1.2em;
                margin-top: -.2em;
                border-top: .4em solid white;
                border-left: .4em solid transparent;
                border-right: .4em solid transparent;

                @media (min-width:map-get($grid-breakpoints, md)){
                    right: 1em;
                    margin-top: -.1em;
                    border-top: .3em solid white;
                    border-left: .3em solid transparent;
                    border-right: .3em solid transparent;
                }
            }
        }

        &.btn--qdaspopup {
            border-radius: 2px;
            position: relative;
            text-align: center;
            height: 50px;
            line-height: 30px;
            padding-left: 30px;
            justify-content: center;
            align-items: center;
            background-color: map-get($colors-dark, grey-light);
        
            @media (max-width:map-get($grid-breakpoints, sm)){
                padding-left: 20px;
                height: 40px;
            }

            &.btn--dropdown-corners { 
                border-radius: $border-radius-stnd;
            }
        }

        &.btn--qdaspin {
            border-radius: 0.7em;
            text-align: center;
            background-color: map-get($colors-dark, grey-light);
            position: absolute;
            bottom: -9px;
            right: -9px;
        }

        &.btn--seamless {
            background-color: map-get($colors, grey100);
            color: #33404e;

            i {
                background-color: map-get($colors, grey100);
            }
        }

        // buttons with icons
        //--------------------------------------------------------

            &.btn--reload {
                background-image: url(./../../img/icons/icon-reload.svg);
                background-position: center center;
                background-size: 60%;
                border-left: none !important;
                border-top: none !important;
                border-bottom: none !important;
            }

        // pin buttons
        //--------------------------------------------------------
            
            &.btn--pin {
                height: 30px;
                width: 30px;
                @media (min-width:map-get($grid-breakpoints, lg)){
                    height: 25px;
                    width: 25px;
                }
                padding: 0;
                border-radius: 50%;

                &.btn--pin-trash {
                    background-color: map-get($colors-dark, danger);
                    background-image: url(./../../img/icons/icon-plus-white.svg);
                    background-size: 110%;
                    background-position: center center;
                    transform: rotate(-45deg);
                }

                &.btn--pin-counter {
                    background-color: map-get($colors-dark, danger);
                }

                &.btn--pin-edit {
                    background-color: map-get($colors-dark, action);
                    background-image: url(./../../img/icons/icon-edit-white.svg);
                    background-size: 50%;
                    background-position: center center;
                    margin-right: 1.5em;
                
                    @media (max-width:map-get($grid-breakpoints, sm)){
                        margin-right: 0.7em;
                    }
                }

                &.btn--pinned {
                    position: absolute;
                    top:-9px;
                    right:-9px
                }
            }
    }

    // buttons group
    //--------------------------------------------------------

    .group-btn {

        display:flex;

        .btn {
            display:inline-block;
            border-radius: 0;
            background-color: map-get($colors, grey-dark);
            width: 200px;

            &.btn--badge {
                padding: .5em .7em;
                border-radius: 1em;
            }

            &.btn--active {
                background-color: map-get($colors-dark, action);
                color: white;
            }

            &:first-child {
                border-radius: 2em;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            &:last-child {
                border-radius: 2em;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        .btn--dark {
            &.btn--active {
                background-color: white;
                color: map-get($colors, primary);
            }
        }

        &.group-btn--even {
            .btn {
                width: 50%;
            }
        }

        &.group-btn--corners {
            .btn {
                &:first-child {
                    border-radius: 4px;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
    
                &:last-child {
                    border-radius: 4px;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }




//*******************************************************
// Dark Mode
//*******************************************************


    .dark-mode {
        .btn {

            border: 1px solid white;

            &.btn--small {
                height: 40px;
                line-height: 40px;
            }

            &.btn--dark {
                background-color: map-get($colors-dark, action-dark);
            }

            &.btn--transparent {
                background-color: transparent;
                border: 1px solid rgba(345, 345, 345, .15);
            }

            &.btn--grey {
                background-color: map-get($colors-dark, grey-feather);
                i {
                    background-color: map-get($colors-dark, grey-feather);
                }
            }

            &.btn--seamless {
                background-color: map-get($colors-dark, seamless);
                color: white;
                
                i {
                    background-color: map-get($colors-dark, seamless);
                    border-right: 1px solid map-get($colors-dark, dark); ;
                }
            }

            &.btn--cancel {
                background-color: transparent;
                color: map-get($colors, warning);

                &:hover {
                    background-color: map-get($colors, warning);
                    color: white;
                }
            }

            &.btn--dropdown {
                border: 1px solid white;
            }


            // buttons with icons
            //--------------------------------------------------------

                &.btn--reload {
                    background-image: url(./../../img/icons/icon-reload-white.svg);
                }


            // icon not filled buttons
            //--------------------------------------------------------

                &.btn--icon{

                    // background-color: map-get($colors-dark, seamless);


                    &.btn--action,
                    &.is-active {
                        background-color: map-get($colors-dark, action);
                    }

                    &.btn--action-dark {
                        background-color: map-get($colors-dark, action-dark);
                    }
                }


            // colors
            //--------------------------------------------------------

                &.btn--negative {
                    background-color: white;
                    color: map-get($colors-dark, action-dark);

                    &.btn--icon-check {
                        background-image: url(./../../img/icons/icon-checkmark.svg);
                    }
                }

                &.btn--active {
                    background-color:map-get($colors-dark, action);
                }

                &.disabled,
                &:disabled {
                    border-color: transparent !important;
                    color:rgba(345, 345, 345, .3);
                    background-color: map-get($colors-dark, seamless) !important;
                }
        }

        .group-btn {

            .btn {
                background-color: map-get($colors-dark, grey-light);

                &.btn--active {
                    background-color: map-get($colors-dark, action);
                }

                &.btn--dark {
                    
                    background-color: map-get($colors-dark, action-dark);

                    &.btn--active {
                        background-color: white;
                    }
                }

                &.btn--transparent {
                    
                    background-color: transparent;
                    border: 1px solid white;

                    &.btn--active {
                        background-color: white;
                        color: map-get($colors-dark, action-dark);
                    }
                }
            }

            .btn--function {
                background-color: white;
                color: map-get($colors, primary);
            }
        }
    }


//*******************************************************
// light mode
//*******************************************************

    .light-mode {
        .btn {
            border: 1px solid map-get($colors-dark, dark);

            // &.btn--icon{ 
            //     // background-color: map-get($colors, grey100);
            // }

            &.btn--icon-edit {
                &.is-active {
                    background-color: map-get($colors-dark, action);
                    background-image: url(./../../img/icons/icon-checkmark-white.svg);
                }
            }

            &.btn--blue {
                background-color: rgba(map-get($colors-dark, action-active), 1);
            }

            &.btn--action-dark {
                background-color: map-get($colors-dark, action-dark);
            }

            &.btn--reset {
                color: map-get($colors, grey);
            }

            &.btn--grey {
                background-color: map-get($colors, grey100);
                color: map-get($colors-dark, dark);
                i {
                    background-color: map-get($colors, grey100);
                }
            }

            &.disabled,
            &:disabled {
                border-color: transparent !important;
                color:rgba(46, 58, 72, .5);
                background-color: map-get($colors, grey100) !important;
            }
        }
    }