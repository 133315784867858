//*******************************************************
// fadeIn (disappear)
//*******************************************************

    .animate {

        max-height: 100px;

        &.animate--disappear {
            opacity: 0;
            max-height: 0;
            margin: 0 !important;
            visibility: hidden;
            transition: opacity map-get($times, fast) linear, 
                        margin map-get($times, fast) linear,
                        max-height map-get($times, fast) ease-in-out map-get($times, fast)/2, 

        }

        &.animate--appear {
            opacity: 1;
            max-height: 600px;
            visibility: visible;
            @media (min-width:map-get($grid-breakpoints, md)){
                max-height: 600px;
            }

            @media (min-width:map-get($grid-breakpoints, lg)){
                max-height: 200px;
            }
            transition: max-height map-get($times, fast) linear, 
                        margin map-get($times, fast) linear,
                        opacity map-get($times, fast) ease-in-out map-get($times, fast)/2;
        }
    }

//*******************************************************
// Slidein out
//*******************************************************

    .slide--out {
        position: relative;
        opacity: 0;
        top:100vh;
        transition: opacity .3s ease-in-out 1s, transform .3s ease-in-out, max-height 1.5s ease-in-out;
        max-height: 0;
    }

    .slide--in {
        transform: translateY(0);
        opacity: 1;
        transition: opacity .3s ease-in-out 1.5s, transform .3s ease-in-out, max-height 1.5s ease-in-out 1s;
        top:0;
        max-height: 1000px;
    }

//*******************************************************
// Rasp
//*******************************************************

    .rasp-animation {
        text-align: center;
        overflow: hidden;

        .cable {
            animation-name:connect;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .app--connectIsLoading {
        .rasp-animation {
            .cable {
                animation-fill-mode: forwards;  
                animation-iteration-count: 1;    
                
                &:after {
                    content: '';
                    display:block;
                    height: 100%;
                    width:5px;
                    top:0;
                    left:50%;
                    position: absolute;
                    transform: translateX(-50%);
                    background-size: 30px 30px;
                    background-image: linear-gradient(
                        135deg,
                        rgba(255, 255, 255, .15) 25%,
                        transparent 25%,
                        transparent 50%,
                        rgba(255, 255, 255, .15) 50%,
                        rgba(255, 255, 255, .15) 75%,
                        transparent 75%,
                        transparent
                    );
                    animation: animate-stripes 0.6s linear infinite;
                    animation-direction: reverse;
                }
            }
        }
    }

    @keyframes animate-stripes {
        0% {
            background-position: 0 0;
        }
    
        100% {
            background-position: 60px 0;
        }
    }

    /* The animation code */
    @keyframes connect {
        from {transform: translateY(100px);}
        to {transform: translateY(-19px);}
    }