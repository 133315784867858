.paging {
    display: flex;
    align-items: center;

    .paging__total {
        margin-right:1em;
        text-transform: uppercase;
    }

    .paging__pages-status {
        margin: 0 .5em;
    }

    .btn {
        margin: 0 .25em;
        display: inline-block
    }

    .paging__pages {
        display:flex;
        align-items: center;
    }
}