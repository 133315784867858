.editable {

    .editable__input,
    .editable__output {
        height: 100%;
    }

    &.editable--off {
        .editable__visible {
            display:none;
        }
    
        .editable__hidden {
            display:inherit;
            align-items: center;
        }
    }

    &.editable--on {
        .editable__visible {
            display:inherit;
        }
    
        .editable__hidden {
            display:none;
            align-items: center;
        }
    }
}