.nests {
    padding-bottom: 20px;
}
.nest {
    display: flex;
    align-items: center;

    label {
        font-size: 1.2em;
        flex: 0 0 30%;
    }

    .nest__content {
        flex: 0 0 70%;
        display: flex;
    }

    .nest__input {
        flex: 0 0 70%;
    }

    .nest__btn {
        flex: 0 0 40px;
    }

    @media (min-width:map-get($grid-breakpoints, lg)){
        .nest__btn {
            flex: 0 0 45px;
        }
    }

    @media (min-width:map-get($grid-breakpoints, xl)){
        .nest__btn {
            flex: 0 0 54px;
        }
    }
}