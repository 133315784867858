/*loop over all breaking points*/
@each $query_name, $query_value in $grid-breakpoints {    

    //if breaking point not empty (= false)
    @if map-get($spacing, $query_name) != null {            

        @media (min-width: $query_value){
        
            @each $name, $value in map-deep-get($spacing, $query_name) {

                @if $name != null {
                    .a-#{$name} {
                        margin-bottom: $value;
                    }

                    .s-#{$name} {
                        padding: $value;
                    }

                    .st-#{$name} {
                        padding-top: $value;
                    }

                    .sb-#{$name} {
                        padding-bottom: $value;
                    }

                    .sr-#{$name} {
                        padding-right: $value !important;
                    }

                    .a-#{$query_name}-#{$name} {
                        margin-bottom: $value;
                    }
                }
                
            }
        }
    }  
}