.qdaspopup-wrapper {
    display: flex;
    justify-content: center;
    margin-right: 1.5em;

    @media (max-width:map-get($grid-breakpoints, sm)){
        margin-right: 0.7em;
    }


}

.qdaspopup {

    position: relative;
    margin-right: 5px;
    width: 100px;

    @media (max-width:map-get($grid-breakpoints, lg)){
        width: 95px;
    }

    @media (max-width:map-get($grid-breakpoints, md)){
        width: 90px;
    }

    @media (max-width:map-get($grid-breakpoints, sm)){
        width: 70px;
        margin-right: 1px;
    }
    

    .qdaspopup__label {
        max-width: 100%;
        width: 100%;
    }

    &.qdaspopup--checkbox {
        .qdaspopup__label {
            width: auto;
        }   
    }

    .qdaspopup__overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        left:0;
        background-color: rgba(0, 0, 0, .5);
        display: flex;
        justify-content: center;
        align-items:center;
        opacity: 0;
        z-index: -1;
        top: 0;
        transform: translateY(-50%);

        @media (min-width:map-get($grid-breakpoints, md)){
            top: 50%;
            transform: translateY(-50%);
        }

        &.qdaspopup__overlay--active {
            opacity: 1;
            z-index: 12;
            position: fixed;
            transform: translateY(0);

            @media (min-width:map-get($grid-breakpoints, md)){
                transform: translateY(-50%);
            }

            .qdaspopup__content {
                transform: translate(0, 0);
            }
        }

        .qdaspopup__header {
            position: absolute;
            top: 2em;
            left:2em;
            width: calc(100% - 2em);
            z-index: 1;
        }

        .qdaspopup__content {
            transition: all map-get($times, fast);
            width: 100%;
            transform: translateY(600%);
            max-width: 750px;
            @media (min-width:map-get($grid-breakpoints, md)){
                width: 50%;
                transform: translateY(1000%);
            }
            padding: 2em;
            padding-top: 100px;
            background-color: #f3f3f3;
            position: relative;

            &.qdaspopup__content--with-footer {
                padding-bottom: 100px;

                .qdaspopup__list {
                    height: calc(100vh - 100px - 100px);
                    @media (min-width:map-get($grid-breakpoints, md)){
                        height: 40vh;
                    }
                }
            }
        }

        .qdaspopup__list {
            overflow-y: scroll;
            overflow-x: hidden;
            height: calc(100vh - 100px);
            @media (min-width:map-get($grid-breakpoints, md)){
                height: 40vh;
            }
        }

        .qdaspopup__footer {
            position: absolute;
            display: flex;
            flex-direction: column;
            bottom: 1em;
            left: 0;
            width: 100%;
            text-align: center;
            align-items: center;
        }

        .qdaspopup__sublist {
            
            background-color: #f3f3f3;

            .qdaspopup__sublist-item {
                padding: 1em;
                border-bottom: 1px solid map-get($colors, seamless);

                &:hover {
                    // background-color: map-get($colors-dark, border);
                    background-color: map-get($colors, grey100);
                }

                &:last-child{
                    border:none;
                }

                &.qdaspopup__sublist-item--checked {
                    
                    position: relative;

                    &:after {
                       content: '';
                       display:block;
                       height: 1em;
                       width:1em;
                       top:50%;
                       margin-top: -.5em;
                       right:1em;
                       position: absolute;
                       background-color: map-get($colors, success);
                       border-radius: 50%;
                    }
                }
            }
        }
    }

    &.qdaspopup--parts {
        width: 50%;

        @media (min-width:map-get($grid-breakpoints, md)){
            width: auto;
        }
    }
}

//*******************************************************
// dark mode
//*******************************************************

    .dark-mode {
        .qdaspopup{
            .qdaspopup__overlay {
                .qdaspopup__content {
                    background-color: map-get($colors-dark, dark);
                }

                .qdaspopup__sublist {
            
                    background-color: map-get($colors-dark, dark);
        
                    .qdaspopup__sublist-item {
                        border-bottom: 1px solid map-get($colors-dark, seamless);

        
                        &:hover {
                            background-color: map-get($colors-dark, grey-medium);
                        }
                    }
                }
            }
        }
    }