.attribute_form {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    // overflow-y: scroll;


    .attribute_form__header {
        padding: 0 15px;
    }

    .attribute_form__item {
        opacity: 1;
        transform: translate(0, 0);
        transition: opacity 1s ease-in-out, transform 1s ease-in-out;
        position: relative;
        max-height: 100vh;
    }

    .attribute_form__mobileSwitch {
        opacity: 1;
        transform: translate(0, 0);
        transition: opacity 1s ease-in-out, transform 1s ease-in-out, max-height 1s ease-in-out;
        max-height: 40px;
        padding: 0 15px;
    }

    .attribute_form__main {
        transition: margin .5s ease-in-out, top .5s ease-in-out;
        position: absolute;
        top: 120px;
        // transform: translate(0, calc(-50% + 60px));
        width: calc(100%);
        left: 15px;
        overflow:hidden;

        @media (min-width:map-get($grid-breakpoints, sm)){
            top: 60px;
            transform: translate(0, 0);
        }

        @media (min-width:map-get($grid-breakpoints, lg)){
            top: 50%;
            transform: translate(0, calc(-50% + 25px));
        }
    }

    .attribute_form__apply {
        position: absolute;
        top:0;
        right: 15px;
        z-index: 1;
        transition: all .3s;
    }

    .taster__pocket {
        transition: all 1s ease-in-out;
        position: absolute;
        bottom: 0;
        left:0;
        max-height: 420px;
        @media (min-width:map-get($grid-breakpoints, lg)){
            max-height: 208px;
        }
    }

    .attribute_form__values,
    .attribute_form__toggles {
        max-height: 490px;
    }

    .taster {
        @media (max-width:map-get($grid-breakpoints, lg)){
            margin: 6vh auto 5vh auto;
        }
    }


    //*******************************************************
    // if config mode
    //*******************************************************

    &.attribute_form--config {

        min-height: 500px;

        .attribute_form__item--for-default {
            opacity: 0;
            z-index: -1;
        }

        .attribute_form__mobileSwitch {
            max-height: 0;
            opacity: 0;
            z-index: -1;
        }

        .attribute_form__header {
            transition: opacity 1s ease-in-out, transform 1s ease-in-out, max-height 1s ease-in-out 1s;
            transform: translate(0, -10%);
            @media (max-width:map-get($grid-breakpoints, md)){
                transition: opacity 0s;
                transform: translate(0, 0);
            }
        }   

        .attribute_form__toggles {
            transition: opacity 1s ease-in-out, transform 1s ease-in-out, max-height 1s ease-in-out;
            transform: translate(-10%, 0);
            @media (max-width:map-get($grid-breakpoints, md)){
                transform: translate(0, 0);
            }
        }

        .attribute_form__values {
            transition: opacity 1s ease-in-out, transform 1s ease-in-out, max-height 1s ease-in-out;
            transform: translate(10%, 0);
            @media (max-width:map-get($grid-breakpoints, md)){
                transform: translate(0, 0);
            }
        }

        .attribute_form__main {
            top:50px;
            @media (min-width:map-get($grid-breakpoints, md)){
                top:-30%;
            }
            @media (min-width:map-get($grid-breakpoints, lg)){
                top:50%;
            }
        }

        .attribute_form__assign {
            transition: opacity 1s ease-in-out, transform 1s ease-in-out;
            transform: translate(0, -10%);
        }

        .taster__angle-wrapper {
            visibility: hidden;
            opacity: 0;
        }
    }

    //*******************************************************
    // if default mode
    //*******************************************************

    &.attribute_form--default {
        
        .attribute_form__item--for-config {
            opacity: 0;
            z-index: -1;
        }   

        .attribute_form__apply {
            transform: translate(10%, 0%);
            opacity: 0;
            z-index: -1;
        }

        .taster__pocket {
            opacity: 0;
            // transform: translate(0, 50%);
            z-index: -1;
        }
    }

    //*******************************************************
    // fix the keyboard on android 
    //*******************************************************

        // @media (max-width:map-get($grid-breakpoints, sm)){
        //     min-height: 650px;
        // }

        // @media (min-width:map-get($grid-breakpoints, md)) and (max-width:map-get($grid-breakpoints, lg)) and (max-aspect-ratio: 2/1){
        //     min-height: 920px;    
        // }

        // @media (max-height:580px) and (max-aspect-ratio: 8/7){
        //     min-height: 820px;    
        // }

        // @media (max-height:700px) and (min-aspect-ratio: 5/3){
        //     min-height: 1000px;    

        //     .attribute_form__main {
        //         top:120px;
        //         transform: translate(0, 0)
        //     }
        // }
}

