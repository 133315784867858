.taster {
    position: relative;
    transition: all, map-get($times, fast);
    height:0;
    padding-bottom: 20vh;
    width: 20vh;
    margin: 9vh auto 9vh auto;
    

    @media (min-width:map-get($grid-breakpoints, md)){
        padding-bottom: 60%;
        width: 60%;
        margin: 9vw auto 9vw auto;
    }

    @media (min-width:map-get($grid-breakpoints, lg)){
        padding-bottom: 65%;
        width: 65%;
        margin: 9vw auto 9vw auto;
    }

    @media (min-width:map-get($grid-breakpoints, xl)){
        padding-bottom: 234px;
        margin: 35% auto 20% auto;
        width: 234px;
    }

    .taster__wrapper {
        position: absolute;
        background-color: map-get($colors-dark, action-dark);
        display: inline-block;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        top:0;
        left:0;
        transition: all, map-get($times, fast);
    }

    &:after {
        content: '';
        display:block;
        height: 60%;
        width:60%;
        top:50%;
        left:50%;
        border: 3px solid white;
        @media (min-width:map-get($grid-breakpoints, md)){
            border: 6px solid white;
        }
        transform: translate(-50%, -50%);
        position: absolute;
        border-radius: 50%;
        transition: all, map-get($times, fast);
    }

    .taster__item {
        box-sizing: content-box;
        border: 5px solid map-get($colors-dark, action);
        width: 35%;
        padding-bottom: 35%;        

        @media (min-width:map-get($grid-breakpoints, lg)){
            width: 60px;
            padding-bottom: 60px;
        }
        
        @media (min-width:map-get($grid-breakpoints, xl)){
            width: 80px;
            padding-bottom: 80px;
        }
        
        position: absolute;
        border-radius: 50%;
        transition: all, map-get($times, fast);
        cursor: pointer;
        z-index: 2;

        .taster__angle-wrapper { 
            opacity: 1;
            transition: opacity .3s;
            position:absolute;
            top: -200%;
            left:-100%;
            @media (min-width:map-get($grid-breakpoints, md)){
                left: -50%;
            }

            &:after {
               content: '\00b0';
               display:block;
               top:20%;
               right:10%;
               position: absolute;
               color: white;
               font-size: 1.5em;
            }
        }

        &.taster__item--2 {
            .taster__angle-wrapper {
                left: inherit;
                right: -100%;
                @media (min-width:map-get($grid-breakpoints, md)){
                    right: -50%;
                }
            }
        }

        .taster__item-values {
            position: absolute;
            height: 100%;
            width: 40%;
            left: -55%;
            top: 50%;
            transform: translate(-50%, -50%);

            .btn {
                position: absolute;
                left: 45%;
                top: 50%;
                transform: translate(-50%, -50%);
                // width: 4em;
                width: auto;
                height: 40px;
                line-height: 40px;

                @media (min-width:map-get($grid-breakpoints, md)){
                    // width: 5em;
                    width: auto;
                }
            }

            .chart {
                left: 50%;
                top: -60%;
                transform: translate(-50%, -50%);
                background-color: map-get($colors-dark, seamless);

                @media (min-width:map-get($grid-breakpoints, md)){
                    top: -30%;
                }

                @media (min-width:map-get($grid-breakpoints, lg)){
                    top: -40%;
                }
            }
        }

        button {
            top:0;
            left:0;
            position: absolute;
            width: 100%;
            height: 100%;
            line-height: 100%;
            background-color: map-get($colors-dark, action-dark);
            border: 3px solid transparent;

            &:hover {
                opacity: 1;
            }
        }

        .taster__angle-wrapper {
            position: absolute;
            width: 5em;
            text-overflow: ellipsis;
            text-align: center;
        }

        &.taster__item--active {
            
            button {
                border: 3px dashed white;
            }
        }

        &.taster__item--1 {
            top: -30%;
            left: 50%;
            transform: translateX(-50%);
        }

        &.taster__item--2 {
            bottom: -5%;
            right: -20%;

            .taster__item-values {
                left: 160%;
            }
        }

        &.taster__item--3 {
            bottom: -5%;
            left: -20%;
        }

        // if loaded
        //--------------------------------------------------------

            &.taster__item--loaded {

                button {
                    background-color: white;
                    color: map-get($colors-dark, action-dark);
                    overflow: visible;
                    background-image: none !important;

                    &:after {
                        content: '';
                        display:block;
                        height: 30%;
                        width:30%;
                        top:0;
                        right:0%;
                        position: absolute;
                        background-color: map-get($colors-dark, danger);
                        border-radius: 50%;
                        background-image: url(./../../../img/icons/icon-plus-white.svg);
                        transform: rotate(-45deg);
                        background-size: 120%;
                        background-position: center center;
                    }

                    &.is-anschlag {
                        &:before {
                            content: '';
                            display:block;
                            height: 40%;
                            width:40%;
                            top:50%;
                            left:50%;
                            margin-left: -20%;
                            margin-top: -20%;
                            position: absolute;
                            border-radius: 50%;
                            background-color: map-get($colors-dark, action-dark);
                        }
                    }
                }
            }
    }

    // inner measurement
    //--------------------------------------------------------

        &.taster--inner {

            &:after {
                height: 100%;
                width: 100%;
            }

            .taster__item {

                .taster__item-values { 
                    left: 200%;
                }

                &.taster__item--1 {
                    top: 5%;
                    left: 50%;
                    transform: translateX(-50%);

                    .taster__item-values {
                        left: 50%;
                        top: -60%;
                    }
                }
        
                &.taster__item--2 {
                    bottom: 18%;
                    right: 8%;

                    .taster__item-values {
                        @media (min-width:map-get($grid-breakpoints, md)){
                            left: 200%;
                        }
                    }

                    .taster__angle-wrapper {
                        left: inherit;
                        right: -110%;

                        @media (min-width:map-get($grid-breakpoints, sm)){
                            right: -180%;
                        }

                        @media (min-width:map-get($grid-breakpoints, md)){
                            right: -100%;
                        }

                        @media (min-width:map-get($grid-breakpoints, lg)){
                            right: -170%;
                        }

                        @media (min-width:map-get($grid-breakpoints, xl)){
                            right: -120%;
                        }
                    }
                }
        
                &.taster__item--3 {
                    bottom: 18%;
                    left: 9%;

                    .taster__item-values {
                        left: -100%;
                    }
                }

                .taster__angle-wrapper {
                    
                    position:absolute;
                    top: -210%;
                    left:-120%;

                    @media (min-width:map-get($grid-breakpoints, md)){
                        top: -140%;
                        left: -100%;
                    }

                    @media (min-width:map-get($grid-breakpoints, lg)){
                        left: -170%;
                    }

                    @media (min-width:map-get($grid-breakpoints, xl)){
                        left: -120%;
                    }
                }

                &.taster__item--loaded {
                    border-color: transparent;
                }
            }   
        }

    //*******************************************************
    // 2 taster
    //*******************************************************

    &.taster--children-2 {

        &.taster--view-only {
            margin-top: 20px;
        }

        // outer
        //--------------------------------------------------------

            .taster__item {
                &.taster__item--1 {
                    top: 50%;
                    left: -30%;
                    transform: translateY(-50%);
                }
        
                &.taster__item--2 {
                    top: 50%;
                    left:auto;
                    right: -30%;
                    transform: translateY(-50%);
                    bottom: auto;
                }
            }

        // inner
        //--------------------------------------------------------

            &.taster--inner {
                .taster__item {
                    &.taster__item--1 {
                        top: 50%;
                        left: 7%;
                        transform: translateY(-50%);
                    }
            
                    &.taster__item--2 {
                        top: 50%;
                        right: 10%;
                        left:auto;
                        transform: translateY(-50%);
                    }
                }
            }
    }

    // inputs
    //--------------------------------------------------------

    .taster__angle-wrapper {
        display:none;
    }

    &.taster--show-input {
        .taster__angle-wrapper {
            display:block;
        }   
    }


    //*******************************************************
    // taster length
    //*******************************************************

        @import 'taster-length.scss';


    //*******************************************************
    // if view only
    //*******************************************************


        @import 'taster-view-only.scss';


    //*******************************************************
    // as icon
    //*******************************************************

        @import "taster-icon.scss";
    
}


    //*******************************************************
    // POCKET
    //*******************************************************

        @import "taster-pocket.scss";


    //*******************************************************
    // hide delete icons
    //*******************************************************

        .taster--hideChannelsDeleteIcons {
            .taster__item.taster__item--loaded button {
                &:after {
                   display:none;
                }
            }
        }

    //*******************************************************
    // 
    //*******************************************************

        .swiper-slide {
            .is-anschlag {
                &:before {
                    background-color: map-get($colors-dark, seamless) !important;
                }
            }
        }


    //*******************************************************
    // dark
    //*******************************************************

        .dark-mode {
            .taster {
                .taster__item {
                    
                    button {
                        background-color: map-get($colors-dark, action-dark);
                    }   

                    &.taster__item--loaded {
                        button {
                            background-color: white;
                            color: map-get($colors-dark, action-dark);
                            overflow: visible;
                        }
                    }
                }    

                &.taster--icon {
                    .taster__wrapper {
                        border-color: white
                    }

                    .taster__item {
                        width: 20%;
                        padding-bottom: 20%;
                        border: 2px solid white !important;
                        background-color: white;
                    }
                }
            }   
        }

    //*******************************************************
    // light-mode
    //*******************************************************

        .light-mode {
            
            .card--success,
            .card--danger {
                .taster {
                    &.taster--icon {
                        .taster__item {
                            border: 2px solid white !important;
                            background-color: white;
                        }

                        .taster__wrapper {
                            border-color: white;
                        }
                    }
                }
            }           

            .taster {
                .taster__item {
                    button {
                        background-color: map-get($colors-dark, action-dark);
                    }   

                    &.taster__item--loaded {
                        button {
                            background-color: white;
                        }
                    }
                }

                &.taster--icon {
                    .taster__item {
                        border: 2px solid map-get($colors-dark, grey-medium-notransp) !important;
                        background-color: map-get($colors-dark, grey-medium-notransp);
                    }
                }
            }

            .swiper-slide {
                .is-anschlag {
                    &:before {
                        background-color: map-get($colors-dark, seamless) !important;
                    }
                }
            }
        }

        

        