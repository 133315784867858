
@each $name, $value in $colors {
    .bg-#{$name} {
       background-color: $value !important;
    }
}

@each $name, $value in $colors-dark {
    .bg-#{$name} {
       background-color: $value !important;
    }
}