
$chart-height : 100%;
$chart-inner: 60; //in percent %

.chart {
    min-height: $chart-height;
    background-color: map-get($colors-dark, dark);
    position: relative;
    overflow: hidden;
    width: 100%;

    .chart__inner {
        position: absolute;
        height: $chart-inner + 0%;
        top: (100 - $chart-inner) / 2 + 0%;
        width: 100%;
    }

    .chart__labels {
        position: absolute;
        height: $chart-inner + 0%;
        top: (100 - $chart-inner) / 2 + 0%;
        width: 100%;
    }

    .chart__marker {
        height: 1px;
        background-color:#aaa;
        position: absolute;
        width: 100%;
        left: 0;  
        z-index: 2;

        &.chart__marker--ot {
            top:0;
        }

        &.chart__marker--ut {
            bottom:0;
        }

        &.chart__marker--nm {
            bottom:50%;
        }

        &.chart__marker--warn {
            background-color: transparent;
            border-bottom: 1px dashed #aaa;
        }
    }

    .chart__value {
        position: absolute;
        background-color: red;
        width: 100%;
        &.top {
            height: 2px;
            top: 0
        }
        &.bottom {
            height: 2px;
            bottom: 0
        }
    }
}

.chart__horizontal {
    position: absolute;
    top:50%;
    left:50%;
    transform: rotate(90deg);
    transform-origin: center center;
    
    .chart {
        position: absolute;
        height: 100%;
    }
}

