
// heights and widths
//--------------------------------------------------------

    .w-100 {
        width: 100%;
    }

    .w-80 {
        width: 80% !important;
    }

    .w-50 {
        width: 50% !important;
    }

    .w-60 {
        width: 60% !important;
    }

    .w-33 {
        width: 33.3% !important;
    }

    .w-25 {
        width: 25% !important;
    }

    .w-16 {
        width: 16.6666% !important;
    }

    @media (min-width:map-get($grid-breakpoints, md)){
        .w-md-auto {
            width: auto;
        }
    }

    .w-auto {
        width: auto !important;
    }

    .h-100 {
        height: 100%;
    }

    .min-h-100 {
        min-height: 100%;
    }

    .max-w-40 {
        max-width: 40%;
    }

    .max-w-70 {
        max-width: 70%;
    }

    .max-w-90 {
        max-width: 90%;
    }



.text-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.position-relative {
    position: relative;
}

@media (min-width:map-get($grid-breakpoints, md)){
    .text-left-md {
        text-align: left;
    }
}

.hidden {
    visibility: hidden;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-y-scroll {
    overflow: hidden;
    overflow-y: scroll;
}