.notification {
    position: absolute;
    top:0;
    left:0;
    min-height: 100vh;
    z-index: 11;
    width: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
    color: white;

    * {
        position: relative;
        z-index: 2;
    }

    &:after {
       content: '';
       display:block;
       height: 100%;
       width:100%;
       top:0;
       left:0;
       position: absolute;
       background-color: black;
       opacity: .7;
       z-index: 1;
    }

    .notification__wrapper {
        
        background-color: map-get($colors-dark, action);
        min-height: 20vh;
        width: 90%;
        @media (min-width:map-get($grid-breakpoints, md)){
            width: 50vw
        }

        @media (min-width:map-get($grid-breakpoints, lg)){
            width: 40vw
        }

        @media (min-width:map-get($grid-breakpoints, xl)){
            width: 30vw
        }
        padding: 30px;
        position: relative;
        border-radius: 4px;
        text-align: center;

        .notification__messages {
            display:flex;
            align-items: center;
            flex-direction: column;

            * {
                width: 100%;
            }
        }

        .btn--icon-close {
            position: absolute;
            top:-69px;
            right:-66px;
        }
    }
}