.invert-sensors-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding-top: 3em;

    

    @media (min-width:map-get($grid-breakpoints, lg)){
        justify-content: space-between;
        padding: 15px 0;
    }

}