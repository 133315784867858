.rasp_channel {

    width: 33%;
    margin-bottom: 1.5em;
    @media (min-width:map-get($grid-breakpoints, lg)){
      width: auto;
      margin-bottom: 0;
      margin-top: 10px;
    }
    text-align: center;
    

    &.rasp_channel--disabled {
        .rasp_channel__index {
            background-color: map-get($colors-dark, action);
        }
    }

    .rasp_channel__index {
        
        height: 45px;
        width: 45px;
        line-height: 45px;
        margin-bottom: .5em;

        @media (min-width:map-get($grid-breakpoints, md)){
            height: 5vw;
            width: 5vw;
            line-height: 5vw;
            margin-bottom: 1.5em;
        }

        @media (min-width:map-get($grid-breakpoints, xxl)){
            height: 80px;
            width: 80px;
            line-height: 80px;
            margin-bottom: 1.5em;
        }
        color: map-get($colors-dark, action-dark);
        background-color: white;
        display:inline-block;
        border-radius: 50%;
        

        &.rasp_channel__index--anschlag{
            text-indent: -90000px;
            position: relative;

            &:after {
            content: '';
            display:block;
            height: 40%;
            width:40%;
            top:50%;
            left:50%;
            margin-left: -20%;
            margin-top: -20%;
            position: absolute;
            border-radius: 50%;
            background-color: map-get($colors-dark, action-dark);
            }
        }
    }

    .rasp_channel__value {
        color:white;
    }
}

// invert form
//--------------------------------------------------------


    .invert-sensors-wrapper {

        .rasp_channel {
            padding: 15px 0;

            @media (min-width:map-get($grid-breakpoints, lg)){
                width: 12.5%;
            }

            &:nth-child(odd){
                background-color: rgba(white, .07);
            }

            .rasp_channel__index {
                background-color: map-get($colors-dark, action-active);
                color: white;
            }

            .rasp_channel__value {
                .badge {
                    background-color: transparent;
                }
            }
        }

            //toggle
            .radio-toggle__mask {
                &:before {
                    content: '-';
                    font-size: 20px;
                    line-height: 20px;
                    top:50%;
                    right:11px;
                    position: absolute;
                    transform: translateY(-50%);
                    z-index: 2;
                }
            }

            .radio-toggle__custom {
                height:100%;
                width:100%;
                position: absolute;
                top:0;
                left:0;

                &:before {
                    content: '+';
                    font-size: 15px;
                    line-height: 15px;
                    top:50%;
                    left:11px;
                    right: inherit;
                    position: absolute;
                    transform: translateY(-50%);
                    z-index: 2;
                    color: map-get($colors-dark, dark);
                }
            }

        .radio-toggle__input {
            &:checked ~ .radio-toggle__mask {
                &:before {
                    color: map-get($colors-dark, dark);
                }

                .radio-toggle__custom {
                    &:before {
                        // color: white;
                    }
                }
            }

        }

        input {
            max-width: 80%;
            width: auto;
            margin-bottom: 20px;
            text-align: center;
        }
    }
