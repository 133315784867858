$height: 70vh;

.referencer {
    width: 100%;
    left:0;
    @media (min-width:map-get($grid-breakpoints, md)){
        width: calc(100% - #{map-get($app, sidebarWidth-sm)});
        left:#{map-get($app, sidebarWidth-sm)};
    }
    overflow: hidden;
    position: fixed;
    top:0;
    height: 100vh;
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 68px 0 0;

    @media (min-width:map-get($grid-breakpoints, md)){
        position: absolute;
    }

    
    .referencer__slider {

        @media (min-width:map-get($grid-breakpoints, md)){
            margin-top: auto;
        }
        
        .referencer__slide {
            border-radius: $border-radius-stnd;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 50vh;

            @media (min-width:map-get($grid-breakpoints, md)){
                background-color: map-get($colors, grey100);
                border: 1px solid white;
            }   
        }
    }

    .referencer__cta {
        display:flex;
        justify-content: center;
        margin-bottom: auto;
    }

    .referencer__attribute-label {
        max-width: 60%;
        min-width: 60%;
    }
}

//*******************************************************
// light mode
//*******************************************************

    .light-mode {
        .referencer {
            .referencer__slide {
                .taster__item-values {
                    span {
                        background-color: map-get($colors, grey);
                    }
                }
            }
        }
    }

//*******************************************************
// dark mode
//*******************************************************

    .dark-mode {
        .referencer {
            .referencer__slide {

                @media (min-width:map-get($grid-breakpoints, md)){
                    background-color: map-get($colors-dark, grey-feather);
                    border: 1px solid rgba(345, 345, 345, .15);
                }
            }
        }
    }