body {
    font-family: 'Roboto', Arial, sans-serif;
    // line-height: 1.2em;
    line-height: normal;
    font-size: map-deep-get($fonts, xs, p, font-size);
    color: rgba(black, .6);
    position: relative;

    // do not allow chrome on tablet to refresh on swipe gesture
    overscroll-behavior: contain;


    // @media (min-width:map-get($grid-breakpoints, sm)){
    //     font-size: map-deep-get($fonts, sm, p, font-size);
    // }

    @media (min-width:map-get($grid-breakpoints, md)){
        font-size: map-deep-get($fonts, md, p, font-size);
    }

    @media (min-width:map-get($grid-breakpoints, lg)){
        font-size: map-deep-get($fonts, lg, p, font-size);
    }

    // @media (min-width:map-get($grid-breakpoints, xl)){
    //     font-size: map-deep-get($fonts, xl, p, font-size);
    // }

    @media (min-width:map-get($grid-breakpoints, xxl)){
        font-size: map-deep-get($fonts, xxl, p, font-size);
    }
}

//*******************************************************
// Text styles
//*******************************************************

    .text-center {
        text-align: center;
    }

    .text-right {
        text-align: right;
    }

    .text-left {
        text-align: left;
    }

    .text-uppercase {
        text-transform: uppercase !important;
    }

    .text-lowercase {
        text-transform: lowercase !important;
    }

    .text-capitalize {
        text-transform: capitalize !important;
    }

    .text-ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .font-regular {
        font-family: 'Roboto', Arial, sans-serif !important;
        font-weight: normal !important;
    }

    .font-bold,
    strong,
    .strong {
        font-family: 'Roboto-Bold', Arial, sans-serif;
        font-weight: bold;
    }

    // .text-uppercase {
    //     text-transform: uppercase;
    // }

    // .text-underline {
    //     text-decoration: underline;
    // }

    // @media (min-width:map-get($grid-breakpoints, md)){
    //     .text-right-md {
    //     text-align: right;
    //     }

    //     .text-left-md {
    //     text-align: left;
    //     }
    // }

//*******************************************************
// RESET
//*******************************************************

    h1, h2, h3, h4, p, ul {
        margin: 0 0 20px 0;
    }


//*******************************************************
// TYPO SIZES
//*******************************************************

    /*loop over all breaking points*/
    @each $query_name, $query_value in $grid-breakpoints {    

        //if breaking point not empty (= false)
        @if map-get($fonts, $query_name) != false {            

            //loop over all font types (1, 2, 3, p, small) in the breaking point
            @each $name, $value in map-deep-get($fonts, $query_name) {

                .h#{$name}{
                    @media (min-width:map-get($grid-breakpoints, $query_name)){
                        font-size: map-deep-get($value, font-size);
                        // font-weight: bold;
                        // font-family: 'Roboto-Bold', Arial;

                        @if map-deep-get($value, line-height) != null {            
                            line-height: map-deep-get($value, line-height);
                        } @else {
                            // @debug "variables.scss -> $fonts -> #{$query_name)} -> #{$name} -> line-height MISSING. Ignore if intended"
                        };

                        @if map-deep-get($value, font-weight) != null {            
                            font-weight: map-deep-get($value, font-weight);
                        } @else {
                            // @debug "variables.scss -> $fonts -> #{$query_name)} -> #{$name} -> line-height MISSING. Ignore if intended"
                        };
                        
                        //if letter spacing exists
                        @if map-deep-get($value, letter-spacing) != 0px and map-deep-get($value, letter-spacing) != null {            
                            letter-spacing: map-deep-get($value, letter-spacing);
                        }
                    }
                }

                .h#{$name}-flat{
                    @media (min-width:map-get($grid-breakpoints, $query_name)){
                        font-size: map-deep-get($value, font-size) !important;        
                        line-height: map-deep-get($value, font-size) !important;
                    }
                }
            }
        }  
    }


//*******************************************************
// COLORS
//*******************************************************

    @each $name, $value in $colors {
        .text-#{$name} {
            
            color: $value !important;

            a {
                color: $value !important;
            }
        }
    }

    @each $name, $value in $colors-dark {
        .text-dark-#{$name} {
            
            color: $value !important;

            a {
                color: $value !important;
            }
        }
    }

    
    .card__inner {
        .hmini {
            opacity: .7;
        }
    }


//*******************************************************
// links
//*******************************************************

    .dark-mode {
        a {
            color: white;
            text-decoration: none;
        }
    }


//*******************************************************
// lists
//*******************************************************

    ol {
        li {
            margin-bottom: .5em;
        }
    }

    .list {

        .list__item {
            border-top: 1px solid map-get($colors-dark, border);
            transition: all .3s;
            position: relative;
            cursor: pointer;
            -webkit-tap-highlight-color: rgba(0,0,0,0);
            -webkit-tap-highlight-color: transparent; /* For some Androids */
            outline: 0; 

            .list__item-inner {
                display:flex;
                align-items: center;
                padding: .5rem;

                @media (min-width:map-get($grid-breakpoints, sm)){
                    padding: .5rem 1rem .5rem 1rem;
                }
            }

            &:first-child {
                border-top: none;
            }

            &:hover {
                background-color: map-get($colors-dark, grey-medium);
            }

            &.list__item--static {
                cursor: default;
                
                &:hover {
                    background-color: transparent;
                }
            }

            &.list__item--checked {
                &:after {
                   content: '';
                   display:block;
                   height: 15px;
                   width: 15px;
                   top:3em;
                   right: 15px;
                   position: absolute;
                   background-color: map-get($colors, action);
                   border-radius: 50%;
                   margin-top: -.5em;
                }
            }
        }
    }


//*******************************************************
// light mode
//*******************************************************

    .light-mode {
        .list {
            .list__item {
    
                &:hover {
                    background-color: map-get($colors, grey100);
                }
    
                &.list__item--checked {
                    &:after {
                       content: '';
                       display:block;
                       height: 15px;
                       width: 15px;
                       top: 3em;
                       right: 15px;
                       position: absolute;
                       background-color: map-get($colors, action);
                       border-radius: 50%;
                       margin-top: -.5em;
                    }
                }
            }
        }
    }