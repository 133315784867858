.slider {
    position: absolute;
    top: 50%;
    left:0;
    display: flex;
    opacity: 0;
    transition: all .5s;

    &.slider--visible {
        opacity: 1;
    }

    .slider__slide {
        padding: 20px;
        width: 60vw;
        margin: 0 5vw;
        border: 1px solid red;
    }
}