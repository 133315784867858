

.slider__spot-light {
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    @media (min-width:map-get($grid-breakpoints, md)){
        width: calc(100% - #{map-get($app, sidebarWidth-sm)});
        left:#{map-get($app, sidebarWidth-sm)};
    }
    height: 100%;
    background-color: map-get($colors, seamless);
    z-index: -1;
    opacity: 0;
    transition: opacity .3s;

    &.slider__spot-light--visible {
        opacity: 1;
        z-index: 3;
    }
}

//*******************************************************
// dark mode
//*******************************************************

    .dark-mode {
        .slider__spot-light {
            background-color: map-get($colors-dark, dark);
        }
    }