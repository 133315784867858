.app__main{
        
    transition: transform .5s;

    &.app__main-slideOut {
        transform: translate(-100vw);
        position: relative;

        @media (min-width:map-get($grid-breakpoints, md)){
            transform: translate(calc(-100vw + 100px));
        }
    }
}

.radio-toggle {
    .radio-toggle__content {
        position: absolute;
        top:-80px;
        right:calc(-100vw - 15px);
        width: 100vw;
        @media (min-width:map-get($grid-breakpoints, md)){
            right: calc(-100vw + 85px);
            width: calc(100vw - 100px);
        }
    }

    .btn:not(.btn--auto) {
        height: 100%;
        width: 30px;

        @media (min-width:map-get($grid-breakpoints, lg)){
            width: 40px;
        }
    }
}
