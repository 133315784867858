&.taster--icon {
    width: 25px;
    padding-bottom: 25px;
    margin:0;

    &:after {
       display:none;
    }

    .taster__wrapper {
        border: 2px solid map-get($colors-dark, grey-medium-notransp);
        background-color: transparent;
    }

    .taster__item {
        width: 20%;
        padding-bottom: 20%;
    }

    &.taster--length {

        .taster__wrapper {
            height: 14px;
        }

        &.taster--outer {
            .taster__item {
                &.taster__item--1 {
                    left: -25%;
                }
                &.taster__item--2 {
                    right: -24%;
                }
            }
        }

        &.taster--inner {
            .taster__item {
                border:none !important;
                width: 30%;
                padding-bottom: 30%;
            }
        }
    }
}