.taster__pocket {
    background-color: map-get($colors-dark, action-dark);
    position: relative;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @media (min-width:map-get($grid-breakpoints, md)){
        padding: 3em 0;
        justify-content: space-between;
    }

    @media (min-width:map-get($grid-breakpoints, lg)){
        padding: 1em;
        justify-content: space-between;
    }

    @media (min-width:map-get($grid-breakpoints, xl)){
        padding: 1em;
        justify-content: space-between;
    }

    //pointer
    &:before {
       content: '';
       display:block;
       height: 0;
       width:0;
       top:-2em;
       left:50%;
       position: absolute;
       border-left: 2em solid transparent;
       border-right: 2em solid transparent;
       border-bottom: 2em solid map-get($colors-dark, action-dark);
       transform: translateX(-50%);
    }

    //background splash
    // &:after {
    //    content: '';
    //    display:block;
    //    height: 110%;
    //    width:200%;
    //    top:0;
    //    left:-50%;
    //    position: absolute;
    //    background-color: map-get($colors-dark, action-dark);
    //    z-index: -1;
    // }
}