.card {
    // min-height: 200px;
    position: relative;
    word-break: break-all;
    transition: background-color map-get($times, fast);
    @media (max-aspect-ratio: 19/10) {
        padding-bottom: 65%;
    }
    @media (max-aspect-ratio: 1276/698) {
      padding-bottom: 57%;
   }
    @media (max-aspect-ratio: 14/10) {
        padding-bottom: 75%;
    }
    @media (min-aspect-ratio: 19/10) {
        padding-bottom: 60%;
    }

    border-radius: $border-radius-big;

    .card__inner {
        position: absolute;
        top:10%;
        left:10%;
        width: 80%;
        height: 80%;
    }

    // card grid
    //--------------------------------------------------------

        &.card--grid {
            .card__inner {
                display: grid;
                grid-template-columns: 25% 25% 25% 25%;
                grid-template-rows: 30% 30% 40%;
                grid-template-areas:
                    "title title title title"
                    "icon . . chart"
                    "info info info chart";

                @media (min-width:map-get($grid-breakpoints, md)){
                    grid-template-rows: 20% 30% 50%;
                }

                @media (min-width:map-get($grid-breakpoints, lg)){
                    grid-template-rows: 30% 30% 40%;
                }

                @media (min-width:map-get($grid-breakpoints, xl)){
                    grid-template-rows: 23% 30% 50%;
                }

                .card__title {
                    grid-area: title;
                }

                .card__icon {
                    grid-area: icon;
                    align-self: start;
                    margin-top: 4px;
                    margin-left: 2px;
                }

                .card__value{
                    grid-area: info;
                    align-self: end;
                }

                .card__chart {
                    grid-area: chart;
                }
            }
        }


    &.card--edit {
        border: 1px dashed rgba(345, 345, 345, 1) !important;
        transition: all .5s;

        &:active {
            background-color: map-get($colors, grey-dark);
        }
    }

    &.card--success {
        background-color: map-get($colors-dark, success) !important;
        color: white;
    }

    &.card--danger {
        background-color: map-get($colors-dark, danger) !important;
        color: white;
    }
}

// drawer
//--------------------------------------------------------

    .export-tab {
        .card {
            .card__value,
            .card__icon,
            .card__title {
                opacity: .5;
            }
        }
    }


//*******************************************************
// darkMode
//*******************************************************

    .dark-mode {
        .card {
            background-color: map-get($colors-dark, grey-feather);
            border: 1px solid rgba(345, 345, 345, .15);
            color: white;
        }
    }


//*******************************************************
// lightMode
//*******************************************************

    .light-mode {
        .card {
            background-color: map-get($colors, grey100);
            border: 1px solid rgba(345, 345, 345, .15);
        }
    }