@-webkit-keyframes rotation {
    0% {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }   
}

@keyframes rotation {
    0% {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.divAnimateLoading {
  position: relative;
  width:20px;
  height:20px;
  border: 4px solid white;
  -webkit-animation: rotation 1s ease-in-out both infinite;
  animation: rotation 1s ease-in-out both infinite;
}

.padding-10px{
    padding: 10px
}