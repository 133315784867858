input[type="number"],
input[type="password"],
input[type="text"] {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-family: 'Roboto-Bold', Arial, sans-serif;
    font-weight: bold;

    @media (min-width:map-get($grid-breakpoints, lg)){
        height: 45px;
        line-height: 45px;
    }

    @media (min-width:map-get($grid-breakpoints, xl)){
        height: 54px;
        line-height: 54px;
    }
    padding: 0 1em;
    outline: none;
    width: 100%;
    border-radius: $border-radius-stnd;
}

input[type="number"] {
    padding: 0 5px;
    text-align: center;
}

label {
    text-transform: capitalize;
    font-weight: bold;
    font-family: 'Roboto', Arial, sans-serif;
}

.radio-toggle {

    padding: 1rem 0;
    font-weight: bold;

    &.radio-toggle--pointer {
        cursor: pointer;
    }

    .radio-toggle__label {
        font-size: .9em;
        text-transform: none;
    }

    .radio-toggle__label--reset {
        font-size: 1em;
    }

    &.text-uppercase {
        .radio-toggle__label {
            text-transform: uppercase;
        }   
    }

    .radio-toggle__sublabel {
        font-size: .7em;
        text-transform: none;
    }

    .radio-toggle__input {

        display:none;

        ~ .radio-toggle__mask {
            height:$radioHeight;
            width:$radioWidth;
            border-radius: 18px;
            border: 1px solid map-get($colors, grey);
            background-color: map-get($colors, grey100);    
            display: inline-block;
            position: relative;

            &:after {
                content: '';
                display:block;
                height: calc(#{$radioHeight} - 4px);
                width: calc(#{$radioHeight} - 4px);
                top:50%;
                left:2px;
                margin-top: calc((#{$radioHeight}/-2 + 2px));
                position: absolute;
                background-color: white;
                border-radius: 50%;
                transition: all map-get($times, fast);
            }
        }

        &:checked ~ .radio-toggle__mask {
            // border: 1px solid map-get($colors-dark, action);
            background-color: map-get($colors-dark, action);

            &:after {
                left:calc(#{$radioWidth} - #{$handleSize} - 2px)
            }
        }
    }

    &.radio-toggle--blue {

        .radio-toggle__input {
        
            &:checked ~ span {
                border: 1px solid white;
                background-color: map-get($colors-dark, action-dark);
            }
        }
    }

    &.radio-toggle--separator {
        border-bottom: 1px solid map-get($colors-dark, border);
    }
}

.input {
    &.input--radio {
        border: 1px solid #f5f7fb;
        border-radius: $border-radius-stnd;
        font-size: map-deep-get($fonts, xs, 2, font-size);
        background-color: map-get($colors-dark, seamless);
        position: relative;
        padding: 0 1.5em;

        line-height: 40px;
        height: 40px;
        @media (min-width:map-get($grid-breakpoints, sm)){
            line-height: 45px;
            height: 45px;
        }
        @media (min-width:map-get($grid-breakpoints, lg)){
            height: 54px;
            line-height: 54px;
            padding: 0 1.7em;
        }

        &.input--radio-active {
            &:before {
               content: '';
               display:block;
               height: .3em;
               width:.7em;
               top:45%;
               left:.5em;
               position: absolute;
               border-bottom: 1px solid white;
               border-left: 1px solid white;
               transform: rotate(-45deg);
            }
        }
    }
}


//*******************************************************
// dark mode
//*******************************************************

    .dark-mode {

        input[type="number"],
        input[type="password"],
        input[type="text"] {
            background-color: map-get($colors-dark, seamless);
            border: 1px solid white;
            color: white;

            &.input-blue {
                background-color: map-get($colors-dark, action-active);
            }

            &.input-dark {
                background-color: map-get($colors-dark, grey-dark);
            }

            &.input-error {
                border-color: rgba(map-get($colors-dark, danger-negative), .5) !important;
                background-color: rgba(map-get($colors-dark, danger), .5) !important;
            }

            &.input-blue::placeholder {
                color:rgba(345, 345, 345, .7);
            }

            &:focus {
               background: map-get($colors-dark, action-active); 
            }
        }  

        .preferences { 
            .radio-toggle {

                &.radio-toggle--pointer {
                    &:hover {
                        background-color: map-get($colors-dark, darker);
                    }
                }

                .radio-toggle__input {
                    ~ span {
                        background-color: transparent
                    }
                    &:checked ~ span {
                        background-color: map-get($colors-dark, action-dark);
                    }
                }
            } 
        }

        .DateInput_input {
            // background-color: map-get($colors-dark, action) !important;
            // color: white !important;
            // border: 1px solid map-get($colors-dark, border) !important;
    
            &::placeholder {
                color: rgba(white, .5) !important;
            }
        }
    }


//*******************************************************
// light mode
//*******************************************************

    .light-mode {
        .preferences {
            .radio-toggle {

                &.radio-toggle--pointer {
                    &:hover {
                        background-color: white;
                    }
                }
            }
        }

        input[type="number"],
        input[type="password"],
        input[type="text"] {
            background-color: map-get($colors, grey100);
            border: 1px solid map-get($colors-dark, dark);

            &:focus {
                background-color: map-get($colors-dark, action);
                color: white;
            }
        }

        .DateInput_input {
            // background-color: map-get($colors-dark, action) !important;
            // color: white !important;
            // border: 1px solid map-get($colors-dark, border) !important;
    
            &::placeholder {
                color: rgba(map-get($colors-dark, dark), .5) !important;
            }
        }
    }


//*******************************************************
// input-control-label
//*******************************************************

    .input-control-label {

        display: flex;
        align-items: center;

        label {
            width: 50%;
            display: inline-block;
            margin:0;
        }

        input {
            width: 45%;
            margin-left: 5%
        }

        &.input-control-label--big {

            label {
                flex: 0 0 40%
            }
        
            input {
                flex: 0 0 60%;
                margin:0;
            }

            @media (min-width:map-get($grid-breakpoints, xl)){
                label {
                    flex: 0 0 30%
                }
            
                input {
                    flex: 0 0 70%;
                }
            }
        }
        
    }

//*******************************************************
// Datepicker
//*******************************************************

    .DateRangePickerInput_arrow {
        display:none !important;
    }

    .DateRangePickerInput {
        background-color: transparent !important;
    }

    .DateInput {
        background-color: transparent !important;
        margin-right: 5px !important;
        width: 60px !important;
        @media (min-width:map-get($grid-breakpoints, md)){
            width: 130px !important;
            margin-right: 10px !important;
        }
    }

    .DateRangePickerInput_clearDates {
        right: 0 !important;
        margin:0 !important;
    }

    .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
        background-color: map-get($colors-dark, action-active) !important;
        border-color: map-get($colors-dark, action-active) !important;
    }

    .dark-mode {
        .DateRangePickerInput_clearDates_svg {
            fill: white;
        }

        .DateRangePickerInput_clearDates_default:focus, 
        .DateRangePickerInput_clearDates_default:hover {
            background-color: transparent
        }
    }

    .CalendarDay__selected_span {
        background-color: map-get($colors-dark, action) !important;
        border-color: map-get($colors-dark, action-active) !important;
    }

    .CalendarDay__hovered_span {
        background-color: map-get($colors-dark, action) !important;
        border-color: map-get($colors-dark, action-active) !important;
        color: white !important;
    }




.pin {
    width: 220px;
    margin: 0 auto;
    display: flex;

    .pin__item {
        width: 25%;
        display: flex;
        justify-content: center;
        padding: 0 5px;

        input {
            text-align: center;
            font-size: 40px;
            padding:0;
            color: white
        }
    }

    &.pin--puk {
        width: 300px;
        .pin__item {
            width: 16.6665%;
        }
    }
}

