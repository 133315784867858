svg,
img {
    max-width: 100%;
    height: auto;
}

*:not(input) {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.circle-image-wrapper {
    
    position: relative;

    .circle-image {
        overflow: hidden;
        width: 10em;
        height: 10em;
        border-radius: 50%;
    }

    .btn--pinned {
        top:$btnPinHeight/3 !important;
        right: $btnPinHeight/3 !important;
    }
}

.circle-small {
    min-width: $circleImageWidth;
    height: $circleImageWidth;
    overflow: hidden;
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    
    img {
        min-width: $circleImageWidth;
        height: $circleImageWidth;
        border-radius: 50%;
    }
}