&.taster--length {

    width: 45vw;

    @media (min-width:map-get($grid-breakpoints, md)){
        width: 40%;
    }

    @media (min-width:map-get($grid-breakpoints, lg)){
        width: 65%;
    }

    @media (min-width:map-get($grid-breakpoints, xl)){
        width: 234px;
    }

    &:after {
        display:none;
    }

    .taster__wrapper {
        height: 70px;
        border-radius: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 3px solid white;
        
        @media (min-width:map-get($grid-breakpoints, lg)){
            height: 80px;
        }
    }

    .taster__item {

        .taster__item-values {
            left: 50%;
            top: -80%;

            @media (min-width:map-get($grid-breakpoints, md)){
                left: -70%;
                top: 50%;
            }
        }

        &.taster__item--1 {
            left: -45%;
        }

        &.taster__item--2 {

            right: -45%;
            // @media (min-width:map-get($grid-breakpoints, lg)){
            //     right: -45%;
            // }
            

            .taster__item-values {
                @media (min-width:map-get($grid-breakpoints, md)){
                    left: 180%;
                }
            }
        }
    }

    &.taster--inner {
        .taster__item {
            width: 30%;
            padding-bottom: 30%;

            @media (min-width:map-get($grid-breakpoints, lg)){
                width: 60px;
                padding-bottom: 60px;
            }

            .taster__item-values {
                @media (min-width:map-get($grid-breakpoints, md)){
                    top: 50%;
                    left: -130%;
                }
            }

            &.taster__item--2 {
                .taster__item-values {
                    @media (min-width:map-get($grid-breakpoints, md)){
                        left: 240%;
                    }
                }
            }
        }
    }
}