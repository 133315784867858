&.taster--view-only {

    * {
        transition: none !important;

        &:after {
            transition: none !important;
        }
    }

    &:after {
        transition: none !important;
    }
            
    margin-top: 100px;
    margin-bottom: 0;

    @media (min-width:map-get($grid-breakpoints, xxl)){
        margin-top: 120px;
    }
    
    .taster__wrapper {
        background-color: map-get($colors-dark, seamless);
        .taster__item {
            border-color: map-get($colors-dark, seamless);
            &.taster__item--loaded {
                button {
                    color:map-get($colors-dark, seamless);
                    font-size: 1.7em;
                    &:after {
                        display:none;
                    }
                }
            }

            .taster__item-values {
                .chart {
                    @media (min-width:map-get($grid-breakpoints, md)){
                        top: -40%
                    }

                    @media (min-width:map-get($grid-breakpoints, lg)){
                        top: -30%
                    }
                }
            }
        }
    }

    // diameter - outer
    //--------------------------------------------------------

        &.taster--outer.taster--diameter {

            .taster__item {
                .taster__item-values {
                    @media (min-width:map-get($grid-breakpoints, md)){
                        left: -75%
                    }
                }
            }

            .taster__item--2 {
                .taster__item-values {
                    @media (min-width:map-get($grid-breakpoints, md)){
                        left: 180%
                    }
                }
            }
        }

    &.taster--length {
        @media (min-width:map-get($grid-breakpoints, md)){
            width: 35%;
        }

        @media (min-width:map-get($grid-breakpoints, lg)){
            width: 30%;
        }

        &.taster--inner {
            @media (min-width:map-get($grid-breakpoints, md)){
                width: 45%;
            }   
            @media (min-width:map-get($grid-breakpoints, lg)){
                width: 30%;
            }   

            .taster__item {
                @media (min-width:map-get($grid-breakpoints, md)){
                    width: 25%;
                    padding-bottom: 25%;
                }

                .chart {
                    top: -55%;
                }
            }

        }
    }

    @media (min-width:map-get($grid-breakpoints, md)){
        width: 40%;
        padding-bottom: 40%;
    }

    @media (min-width:map-get($grid-breakpoints, lg)){
        padding-bottom: 30%;
        width: 30%;
    }

    @media (min-width:map-get($grid-breakpoints, xl)){
        padding-bottom: 234px;
        width: 234px;
    }


    // 2 children round inner
    //--------------------------------------------------------

        &.taster--children-2 {
            &.taster--diameter {
                &.taster--inner {
                    .taster__item--1 {
                        .taster__item-values {
                            left: -90%;
                            top:50%;
                        }
                    }
                }
            }
        }
}