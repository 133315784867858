.status {

    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 1.5em;
    position: relative;
    min-width: 100px;

    &:after {
        content: '';
        display:block;
        position: absolute;
        height: .8em;
        width:.8em; 
        margin-left: 10px;
        border-radius: 50%;
        background-color: map-get($colors, danger);
        right:0;
        top: 50%;
        transform: translateY(-50%);
    }
    
    &.status--active{
        &:after {
            background-color: map-get($colors, success);
        }   
    }
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &.icon--round {
        border-radius: 50%;
        border: 1px solid map-get($colors-dark, border);
        overflow: visible;

        &.icon--for-btn {
            height: 40px;
            width: 40px;

            @media (min-width:map-get($grid-breakpoints, lg)){
                height: 45px;
                width: 45px;
            }

            @media (min-width:map-get($grid-breakpoints, xl)){
                height: 54px;
                width: 54px;
            }
        }

        &.icon--xxl {
            width: 5em;
            height: 5em;
            padding: 1em;
        }

        &.icon--xl {
            width: 4em;
            height: 4em;
            padding: 1em;
        }

        &.icon--md {
            width: 3.5em;
            height: 3.5em;
            padding: .5em;

            &.icon--border {
                padding: 0;
                border: .2em solid map-get($colors, greyTransp);
            }
        }

        &.icon--border {
            padding: 0;
            border: .5em solid map-get($colors, greyTransp);
        }
    }  
}

//*******************************************************
// taster icons
//*******************************************************

    .icon {
        &.icon--taster {
            max-width: 30px;
        }
    }

    .attribute--icon {
        max-width: 30%;
    }


//*******************************************************
// dark mode
//*******************************************************

    .dark-mode {
        .icon {
            &.icon--round {
                border: 1px solid map-get($colors-dark, border);
            }

            &.icon--border {
                border-color: map-get($colors-dark, seamless);
            }
        }  
        
        .status {
            color: map-get($colors, white05)      
        }
    }