.react-confirm-content,
.app__overlay {

    color: white;

    a {
        color: white;
    }

    .group-btn {
        .btn {
            &.btn--dark {
                
                background-color: map-get($colors-dark, action-dark);

                &.btn--active {
                    background-color: white;
                }
            }
        }
    }

    .btn {
        border: 1px solid white;

        &.btn--negative {
            background-color: white;
            color: map-get($colors-dark, action-dark);

            &.btn--icon-check {
                background-image: url(./../../img/icons/icon-checkmark.svg);
            }
        }

        &.btn--active {
            background-color:map-get($colors-dark, action);
        }

        &.btn--function {
            background-color: white;
            color: map-get($colors, primary);
        }

        &.btn--icon-plus {
            background-image: url(./../../img/icons/icon-plus-white.svg) !important;
        }

        &.btn--icon-close {
            background-image: url(./../../img/icons/icon-plus-white.svg) !important;
        }
    }

    input[type="number"],
    input[type="password"],
    input[type="text"] {
        background-color: map-get($colors-dark, seamless);
        color: white !important;
        border: 1px solid white !important;

        &.input-blue {
            background-color: map-get($colors-dark, action-active);

            &:focus {
                background-color: map-get($colors-dark, action);
            }
        }

        &.input-blue::placeholder {
            color:rgba(345, 345, 345, .7);
        }

        &.input-error {
            border-color: rgba(map-get($colors-dark, danger-negative), .5) !important;
            background-color: rgba(map-get($colors-dark, danger), .5) !important;
        }
    }  

    .radio-toggle {
        .radio-toggle__input {
            ~ span {
                background-color: map-get($colors-dark, action-dark);
            }
            &:checked ~ span {
                background-color: white;

                &:after {
                   background-color: map-get($colors-dark, action);
                }
            }
        }
    } 
}