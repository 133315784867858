@import '../variables.scss';

.language-switch {
    width: 80px;

    .btn {
        border: none !important;
    }
}

.dark-mode {
    .language-switch {
        .btn {
            background-color: map-get($colors-dark, dark);
        }
    }   
}

.light-mode {
    .language-switch {
        .btn {
            background-color: map-get($colors-dark, grey-medium-notransp);

            &.btn--active {
                background-color: map-get($colors-dark, action);
            }
        }
    }   
}