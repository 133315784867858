* {
    box-sizing: border-box;
}

.container-fluid {
    
    padding-right: 0;
    padding-left: 0;
    
    @media (min-width:map-get($grid-breakpoints, md)){
        padding-left: 15px;
        padding-right: 15px;
    }

    &.container-fluid--narrow {
        
        width: 100%;

        @media (min-width:1366px){
            width: calc(100% - 170px);
        }
    }

    &.container-fluid--flag {

        padding-left: calc(#{$container-delta-xs} - 70px);

        @media (min-width:map-get($grid-breakpoints, md)){
            padding-left: calc(#{$container-delta} - 75px) !important;
            padding-right: calc(65px);
        }

        @media (min-width:map-get($grid-breakpoints, xl)){
            padding-left: calc(#{$container-delta} - 55px) !important;
            padding-right: calc(100px);
        }
        
        position: relative;

        .flag {
            position: absolute;
            left: 0;
            width: 42px !important;
            height: 42px !important;
            box-sizing: border-box;
            top: -1px;

            @media (min-width:map-get($grid-breakpoints, md)){
                width: calc(#{$container-delta-minor} - 40px) !important;
                height: calc(#{$container-delta-minor} - 40px) !important;
                top: 50%;
                transform: translateY(-50%);
                left: 10px;
            }

            @media (min-width:map-get($grid-breakpoints, xl)){
                width: calc(#{$container-delta-minor} - 15px) !important;
                height: calc(#{$container-delta-minor} - 15px) !important;
                left:0;
            }
        }
    }

    &.container-fluid--flag-content { // we need this for the content under the flag 
        
        width: 100%;

        @media (min-width:map-get($grid-breakpoints, md)){
            width: calc(100% - 100px);
        }

        @media (min-width:map-get($grid-breakpoints, xl)){
            width: calc(100% - 140px);
        }
    }
    
    &.container-fluid--flag-top { // for the slider
        
        padding-left: 0;

        @media (min-width:map-get($grid-breakpoints, md)){
            padding-left: calc(#{$container-delta} - 65px) !important;
        }

        @media (min-width:map-get($grid-breakpoints, xl)){
            padding-left: calc(#{$container-delta} - 55px + 15px) !important;
        }

        .flag {
            top: 0;
            left: 15px;
            transform: translateY(-7.5px);

            @media (min-width:map-get($grid-breakpoints, md)){
                transform: translateY(0);
            }

            @media (min-width:map-get($grid-breakpoints, xl)){
                transform: translateY(-7.5px);
            }
            
        }
    }
}

//*******************************************************
// attributes
//*******************************************************

    .attribute-mode-switch {
        width: 100%;
        button {
            width: 50% !important;
        }

        @media (min-width:map-get($grid-breakpoints, md)){
            width: 50%;

            button {
                width: 50% !important;
            }
        }

        @media (min-width:map-get($grid-breakpoints, lg)){
            width: auto;

            button {
                width: 200px !important;
            }
        }
    }

    .attribute {

        width: calc(50% - 7.5px);
        margin-bottom: 15px;
        margin-right: 15px;

        &:nth-child(2n) {
            margin-right: 0;
        }

        @media (min-width:map-get($grid-breakpoints, lg)) {
            width: calc(25% - 11.25px);

            &:nth-child(2n) {
                margin-right: 15px;
            }

            &:nth-child(4n) {
                margin-right: 0;
            }
        }

    }

    #attribute-edit-form-wrapper {
        @media (max-width:map-get($grid-breakpoints, lg)){
            width: 100%;
        }
    }