.badge {
    background-color: map-get($colors-dark, action);
    padding: .5em .7em;
    border-radius: 1em;
    color:white;
    display: inline-block;
    width: 6em;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (min-width:map-get($grid-breakpoints, lg)){
      width: 5em;
    }

    @media (min-width:map-get($grid-breakpoints, xl)){
      width: 6em;
    }

    &.badge--fluid{
      min-width: 4em;
      width: auto;
    }

    &.badge--micro {
        background-color: map-get($colors-dark, danger);
        position: absolute;
        top:-1em;
        right:-.7em;
        width: 1.5em;
        height: 1.5em;
        line-height: 1.6em;
        padding: 0;
        border-radius: 50%;
        z-index: 1;
    }

    &.badge--for-toggle {
      height: $radioHeight;
      line-height: $radioHeight;
      padding-top: 0;
      padding-bottom: 0;
      font-size: .7em;
      border-radius: $radioHeight;
    }

    // colors
    //--------------------------------------------------------

      &.badge--secondary {
        background-color: map-get($colors-dark, dark);
      }

      &.badge--success {
        background-color: map-get($colors, success);
      }

      &.badge--grey {
        background-color: map-get($colors-dark, grey-light);
        color: white;
      }
}

.light-mode {
  .badge--secondary {
    background-color: map-get($colors-dark, grey-medium-notransp);
  }
}