.nav {

    margin:0;
    padding:0;

    .nav__item {

        position: relative;
        text-align: center;
        list-style-type: none;
        display: flex;
        text-decoration: none;
        align-items: center;
        transition: all map-get($times, fast);
        background-color: transparent;
        width: 100%;

        &.nav__item--logo {
            a {
                padding-top: 0;
            }
        }

        a {
            display: flex;
            align-items: center;
            padding: 15px 0 15px 15px; 
            width: 100%;
            text-decoration: none;

            @media (min-width:map-get($grid-breakpoints, md)){
                flex-direction: column;
                padding: 20px 0 20px 0; 
            }

            @media (min-width:map-get($grid-breakpoints, xl)){
                flex-direction: column;
                padding: 20px 0 20px 0; 
            }

            @media (min-aspect-ratio: 1024/768) and (max-width:1400px) {
               flex-direction: column;
               padding: 15px 0 15px 0; 
            }

            &.active {

                background-color: map-get($colors-dark, action);
    
                .nav__label {
                    color: white;
                }
    
                .nav__icon {

                    .light {
                        display: block !important;
                    }

                    .dark {
                        display: none !important
                    }
                    
                    &:after {
                        background-color: map-get($colors-dark, action);
                    }
    
                    svg {
                        path,
                        g {
                            fill: white !important;
                            stroke: white !important;
                        }
                    }
                }
            }
        }

        &.nav__item--empty {
            padding: 2.5vh 0 2.5vh 15px; 

            @media (min-width:map-get($grid-breakpoints, md)){
                padding: 2.5vh 0 2.5vh 0;
                flex-direction: column; 
            }
        }
        

        &:last-child {
            margin-bottom: 0;
        }

        &.nav__item--scale {
            svg {
                max-width: 40%;
            }
        }
    }

    .nav__label {
        text-transform: uppercase;
        font-family: 'Roboto-Bold', Arial, sans-serif;
        font-weight: bold;
        line-height: 15px;
          font-size: 10px;
        // line-height: 1.3em;
        //   font-size: .65em;
          margin-left:0;
        color: rgba(black, .6);

    }


    .nav__icon {
        width: 2.5em;
        position: relative;
        display: flex;
        align-items: center;

        @media (min-width:map-get($grid-breakpoints, md)){
            margin-bottom: .5em;
        }  

        img {
            z-index: 1;
            position: relative;
        }

        svg {
            display: block;
            position: relative;
            z-index: 1;
            width: 100%;

            path,
            g {
                fill: rgba(black, .5) !important;
                stroke: rgba(black, .5) !important;
                @media (min-width:map-get($grid-breakpoints, md)){
                    fill: rgba(black, .6) !important;
                    stroke: rgba(black, .6) !important;
                }
            }
        }

        &.logo {
            @media (min-width:map-get($grid-breakpoints, md)){
                margin-top: .5em;
            }
        }

        // &:after {
        //    content: '';
        //    display:block;
        //    height: 3.5em;
        //    width:3.5em;
        //    top:50%;
        //    left:50%;
        //    position: absolute;
        //    z-index: 0;
        //    border-radius: 50%;
        //    transform: translate(-50%, -50%);
        // }
    }
}

.nav__opener {

    width: 30px;
    position: fixed;
    height: 20px;
    z-index: 12;
    top: 17px;
    left: 15px;

    &:before {
        content: '';
        display:block;
        height: 1px;
        background-color: black;
        width: 100%;
        top: 1px;
        left:0;
    }

    &:after {
       content: '';
       display:block;
       height: 1px;
       width:60%;
       top:8px;
       left:0px;
       position: absolute;
       background-color: black;
       border-radius: 3px;
    }

    @media (min-width:map-get($grid-breakpoints, md)){
        display:none;
    }
}

//*******************************************************
// light mode
//*******************************************************

    .light-mode {
        .nav {
            .nav__item { 
                .nav__icon {
                    .light {
                        display:none;
                    }
                }
            }
        }
    }


//*******************************************************
// dark mode
//*******************************************************

    .dark-mode {

        .nav__opener {

            &:before {
               background-color: white;
            }
            &:after {
               background-color: white;
            }
        
            @media (min-width:map-get($grid-breakpoints, md)){
                display:none;
            }
        }

        .nav {
        
            .nav__item { 
        
                svg {
                    
                    path,
                    g {
                        fill: rgba(white, .5)!important;
                        stroke: rgba(white, .5)!important;
                    }
                }

                .nav__label {
                    color: white;
                }

                .nav__icon {
                    .dark {
                        display:none;
                    }
                }

                a.active {
                    .nav__label {
                        color: white;
                    }
                }

                &.nav__item--separator-top {
                    border-top: 1px solid map-get($colors-dark, border);
                }

                &.nav__item--separator-bottom {
                    border-bottom: 1px solid map-get($colors-dark, border);
                }
            }
        }
    }
