.popup {

    position: relative;
    

    .popup__label {
        max-width: 100%;
        width: 100%;
    }

    &.popup--checkbox {
        .popup__label {
            width: auto;
        }   
    }

    .popup__overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        left:0;
        background-color: rgba(0, 0, 0, .5);
        display: flex;
        justify-content: center;
        align-items:center;
        opacity: 0;
        z-index: -1;
        top: 0;
        transform: translateY(-50%);

        @media (min-width:map-get($grid-breakpoints, md)){
            top: 50%;
            transform: translateY(-50%);
        }

        &.popup__overlay--active {
            opacity: 1;
            z-index: 12;
            position: fixed;
            transform: translateY(0);

            @media (min-width:map-get($grid-breakpoints, md)){
                transform: translateY(-50%);
            }

            .popup__content {
                transform: translate(0, 0);
            }

            .popup_spinner{
                height: 200px;
                width: 200px;
                transition: opacity .3s;
                background-image: url(../../img/loader.svg);
                background-position: center center;
                background-repeat: no-repeat;
            }
        }

        

        .popup__header {
            position: absolute;
            top: 2em;
            left:2em;
            width: calc(100% - 2em);
            z-index: 1;
        }

        .popup__content {
            transition: all map-get($times, fast);
            width: 100%;
            transform: translateY(600%);
            max-width: 750px;
            @media (min-width:map-get($grid-breakpoints, md)){
                width: 50%;
                transform: translateY(1000%);
            }
            padding: 2em;
            padding-top: 100px;
            background-color: #f3f3f3;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;

            &.popup__content--with-footer {
                padding-bottom: 100px;

                .popup__list {
                    height: calc(100vh - 100px - 100px);
                    @media (min-width:map-get($grid-breakpoints, md)){
                        height: 40vh;
                    }
                }
            }
        }

        .popup__list {
            overflow-y: scroll;
            overflow-x: hidden;
            height: calc(100vh - 100px);
            @media (min-width:map-get($grid-breakpoints, md)){
                height: 40vh;
            }
        }

        .popup__footer {
            position: absolute;
            bottom: 2em;
            left: 0;
            width: 100%;
            text-align: center;
        }

        .popup__sublist {
            
            background-color: #f3f3f3;

            .popup__sublist-item {
                padding: 1em;
                border-bottom: 1px solid map-get($colors, seamless);

                &:hover {
                    // background-color: map-get($colors-dark, border);
                    background-color: map-get($colors, grey100);
                }

                &:last-child{
                    border:none;
                }

                &.popup__sublist-item--checked {
                    
                    position: relative;

                    &:after {
                       content: '';
                       display:block;
                       height: 1em;
                       width:1em;
                       top:50%;
                       margin-top: -.5em;
                       right:1em;
                       position: absolute;
                       background-color: map-get($colors, success);
                       border-radius: 50%;
                    }
                }
            }
        }
    }

    &.popup--parts {
        width: 50%;

        @media (min-width:map-get($grid-breakpoints, md)){
            width: auto;
        }
    }
}

//*******************************************************
// dark mode
//*******************************************************

    .dark-mode {
        .popup{
            .popup__overlay {
                .popup__content {
                    background-color: map-get($colors-dark, dark);
                }

                .popup__sublist {
            
                    background-color: map-get($colors-dark, dark);
        
                    .popup__sublist-item {
                        border-bottom: 1px solid map-get($colors-dark, seamless);

        
                        &:hover {
                            background-color: map-get($colors-dark, grey-medium);
                        }
                    }
                }
            }
        }
    }