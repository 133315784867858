

.login {
    display:flex;
    background-color: map-get($colors-dark, dark);
    height: 100%;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    color:white;

    .login__wrapper {
        max-width: 95%;

        @media (min-width:map-get($grid-breakpoints, sm)){
            min-width: 370px;  
        }
    }
}